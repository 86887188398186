import { css } from "@emotion/react";

export default {
  flexbox: css`
    display: flex;
    gap: 1.875rem;
  `,
  locationPanel: css`
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    padding: 1.5rem;
  `,
};
