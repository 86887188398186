import React, { ReactElement, useState, useEffect } from "react";
import { getArtistLicensingHistory } from "src/api";
import { ArtistLicensingHistory } from "src/api/types/artist-licensing-history";
import { Card, Table } from "antd";
import { TrackLicensingHistory } from "src/api/types/track-licensing-history";
import { format } from "date-fns";
import styles from "./styles";

interface IProps {
  spotifyId: string | undefined;
  artistImage: string | undefined;
}

const formatTableData = (
  data?: TrackLicensingHistory,
  artistImage?: string
) => {
  const licenseArray = [];
  const episodes = data?.episodes.map((episode) => {
    return {
      track: (
        <div>
          <img src={artistImage} alt="" css={styles.artistThumbnail} />
          <span css={styles.trackTitle}>{data?.name}</span>
        </div>
      ),
      type: "TV Episode",
      airDate: format(new Date(episode.appearance.airDate), "LLLL d yyyy"),
      name: `${episode.showName} ${episode.seasonName} Episode ${episode.episodeNumber}`,
    };
  });

  if (episodes) {
    licenseArray.push(...episodes);
  }

  const movies = data?.movies.map((movie) => {
    return {
      track: (
        <div>
          <img src={artistImage} alt="" css={styles.artistThumbnail} />
          <span css={styles.trackTitle}>{data?.name}</span>
        </div>
      ),
      type: "Movie",
      airDate: format(new Date(movie.appearance.airDate), "LLLL d yyyy"),
      name: movie.name,
    };
  });

  if (movies) {
    licenseArray.push(...movies);
  }

  const games = data?.games.map((game) => {
    return {
      type: "Game",
      track: (
        <div>
          <img src={artistImage} alt="" css={styles.artistThumbnail} />
          <span css={styles.trackTitle}>{data?.name}</span>
        </div>
      ),
      airDate: format(new Date(game.airDate), "LLLL d yyyy"),
      name: game.name,
    };
  });

  if (games) {
    licenseArray.push(...games);
  }

  licenseArray.sort(
    (a, b) => new Date(b.airDate).getTime() - new Date(a.airDate).getTime()
  );

  return licenseArray;
};

const LicenseHistoryPanel = ({
  spotifyId,
  artistImage,
}: IProps): ReactElement => {
  const [licensingHistory, setLicensingHistory] =
    useState<ArtistLicensingHistory | null>(null);
  const episodes = licensingHistory?.tracks.map((track) => track.shows).flat();
  const movies = licensingHistory?.tracks.map((track) => track.movies).flat();
  const games = licensingHistory?.tracks.map((track) => track.games).flat();

  const licenseCount = {
    episodes: episodes?.length,
    movies: movies?.length,
    games: games?.length,
  };
  const dataSource = licensingHistory?.tracks.map((track) =>
    formatTableData(track, artistImage)
  );

  const sorted = dataSource
    ?.flat()
    .sort(
      (a, b) => new Date(b.airDate).getTime() - new Date(a.airDate).getTime()
    );

  const columns = [
    {
      title: "Track",
      dataIndex: "track",
      key: "track",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Placement Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Air Date",
      dataIndex: "airDate",
      key: "airDate",
    },
  ];

  useEffect(() => {
    if (!spotifyId) {
      return;
    }
    void getArtistLicensingHistory(spotifyId).then((response) => {
      setLicensingHistory(response);
    });
  }, [spotifyId]);

  return (
    <div>
      <h1 id="license-history" className="landing-anchor">
        License History
      </h1>
      <Card css={styles.panel}>
        <div css={styles.licenseSummary}>
          <span className="licenseType">
            <span className="count">{licenseCount.episodes || 0}</span> TV
            Episodes
          </span>
          <span className="licenseType">
            <span className="count">{licenseCount.movies || 0}</span> Movies
          </span>
          <span className="licenseType">
            <span className="count">{licenseCount.games || 0}</span> Games
          </span>
        </div>
        <Table
          css={styles.licenseTable}
          columns={columns}
          dataSource={sorted}
          rowClassName="table-row"
          locale={{ emptyText: "No license history found for this song." }}
        />
      </Card>
    </div>
  );
};

export default LicenseHistoryPanel;
