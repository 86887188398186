import { Table, Image, Input, Button } from "antd";
import React, { Fragment, ReactElement } from "react";
import { Link, useHistory } from "react-router-dom";
import { trackLink } from "src/utils/links";
import { format } from "date-fns";
import _ from "lodash";
import LoadingSpinner from "src/components/loading-spinner";
import { searchIcon } from "src/assets";
import styles from "./styles";
import { ISearchOptions, ISearchResult } from "./interface";

const columns = [
  {
    title: "",
    dataIndex: "rank",
    key: "rank",
  },
  {
    title: "",
    dataIndex: "thumbnail",
    key: "thumbnail",
  },
  {
    title: "Song",
    dataIndex: "song",
    key: "song",
  },
  {
    title: "Lyrics",
    dataIndex: "lyrics",
    key: "lyrics",
  },
  {
    title: "Lyrical Theme",
    dataIndex: "theme",
    key: "theme",
  },
  {
    title: "Genre",
    dataIndex: "genre",
    key: "genre",
  },
  {
    title: "Release Date",
    dataIndex: "release",
    key: "release",
  },
  {
    title: "Streams",
    dataIndex: "streams",
    key: "streams",
  },
];

const formatOptions = (options: string[]) => {
  return options.map((option, index) => {
    return (
      <span className="search-term">
        {`"${option}"`}
        {index !== options.length - 1 && ", "}
      </span>
    );
  });
};

const HighlightedLyrics = ({ text = "", highlight = "" }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, "gi");
  const parts = text.split(regex);
  return (
    <span css={styles.lyricColumn}>
      {parts
        .filter((part) => part)
        .map((part) =>
          regex.test(part) ? <mark>{part}</mark> : <span>{part}</span>
        )}
    </span>
  );
};

interface IProps {
  searchOptions: ISearchOptions;
  searchResults: ISearchResult[];
  isLoading: boolean;
  isError: boolean;
}

export default function SearchResults({
  searchOptions,
  searchResults,
  isLoading,
  isError,
}: IProps): ReactElement {
  const history = useHistory();

  const formatTableData = (tracks: ISearchResult[]) => {
    return tracks.map((track, index) => {
      return {
        rank: index + 1,
        thumbnail: (
          <Image
            css={styles.thumbnail}
            preview={false}
            src={track.imageThumbnail}
          />
        ),
        song: (
          <Link
            to={trackLink({
              title: track.title,
              trackId: track.trackId,
            })}
          >
            <span>{track.title}</span>
            <span css={styles.artistName}>{track.artist}</span>
          </Link>
        ),
        lyrics: (
          <HighlightedLyrics
            text={track.lyrics}
            highlight={searchOptions.exact !== null ? searchOptions.exact : ""}
          />
        ),
        theme: track.theme,
        genre: track.genre,
        release: format(new Date(track.releaseDate), "MMM dd, yyy"),
        streams: track.popularityMetricValue.toLocaleString(),
      };
    });
  };

  const dataSource = formatTableData(searchResults);

  if (isError) {
    return (
      <div css={styles.wrapper}>
        <p>Sorry, there was a problem fetching these results.</p>
        <Button onClick={() => history.goBack()}>Back</Button>
      </div>
    );
  }

  return (
    <Fragment>
      <div css={styles.wrapper}>
        {/* TODO: Replace this input with the lyric search input component on the landing page */}
        <Input
          css={styles.searchInput}
          placeholder="Search by Lyric"
          suffix={<img src={searchIcon} alt="search icon" />}
        />
        <hr />
        <p>
          <span className="search-term">&quot;{searchOptions.exact}&quot;</span>{" "}
          exact word or phrase
          {searchOptions.any && searchOptions.any.length > 0 && (
            <span>
              <span className="seperator">|</span> Any of{" "}
              {formatOptions(searchOptions.any)}
            </span>
          )}
          {searchOptions.none && searchOptions.none.length > 0 && (
            <span>
              <span className="seperator">|</span> None of{" "}
              {formatOptions(searchOptions.none)}
            </span>
          )}
        </p>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table
            onRow={(record) => {
              return {
                onClick: () => {
                  history.push(record.song.props.to);
                },
              };
            }}
            css={styles.resultsTable}
            dataSource={dataSource}
            columns={columns}
          />
        )}
      </div>
    </Fragment>
  );
}
