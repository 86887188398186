import React, { ReactElement } from "react";
import { Spin } from "antd";
import styles from "./styles";

const PageLoading = (): ReactElement => {
  return (
    <div className="page-loading" css={styles.loading}>
      <Spin />
      <p>
        <i>Gathering insights...</i>
      </p>
    </div>
  );
};

export default PageLoading;
