import React, { ReactElement, useEffect, useState } from "react";
import styles from "./styles";

interface ArtistProps {
  image: string | undefined;
  name: string;
  genres: string[];
}
const ArtistHeader = ({ image, name, genres }: ArtistProps): ReactElement => {
  const [selectedTab, setSelectedTab] = useState<string | undefined>();
  const isSticky = () => {
    const header = document.querySelector(".sticky-tabs");
    const scrollTop = window.scrollY;
    if (header) {
      return scrollTop >= 250
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    }
    return false;
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  return (
    <div css={styles.headerContainer}>
      <div css={styles.infoContainer}>
        <img src={image} alt="artist" css={styles.image} />
        <div css={styles.textContainer}>
          <h1 css={styles.name}>{name}</h1>
          <p css={styles.genres}>
            {genres.map((g) =>
              genres.indexOf(g) === genres.length - 1 ? `${g}` : `${g}, `
            )}
          </p>
        </div>
      </div>
      <div css={styles.zIndex} className="sticky-tabs">
        <a
          onClick={() => setSelectedTab("spotify")}
          href="#spotify"
          css={[styles.tabs, selectedTab === "spotify" && styles.focus]}
        >
          Spotify
        </a>
        <a
          onClick={() => setSelectedTab("social-media")}
          href="#social-media"
          css={[styles.tabs, selectedTab === "social-media" && styles.focus]}
        >
          Social Media
        </a>
        <a
          onClick={() => setSelectedTab("audience")}
          href="#audience"
          css={[styles.tabs, selectedTab === "audience" && styles.focus]}
        >
          Audience
        </a>
        <a
          onClick={() => setSelectedTab("license-history")}
          href="#license-history"
          css={[styles.tabs, selectedTab === "license-history" && styles.focus]}
        >
          License History
        </a>
        <a
          onClick={() => setSelectedTab("top-tracks")}
          href="#top-tracks"
          css={[styles.tabs, selectedTab === "top-tracks" && styles.focus]}
        >
          Top Tracks
        </a>
      </div>
    </div>
  );
};

export default ArtistHeader;
