import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  songDetails: css`
    display: flex;
    justify-content: space-between;
    gap: 1.875rem;
  `,
  panel: css`
    flex: 1;
    margin-bottom: 1.875rem;
    max-height: 40rem;
    overflow: auto;
    box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
  `,
  sectionHeader: css`
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 1.875rem;
  `,
  detail: css`
    margin-bottom: 0.625rem;
    font-family: proxima-nova, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    span:nth-of-type(1) {
      display: inline-block;
      color: ${colors.gray.dark};
      font-size: 1rem;
      width: 8.625rem;
      &.ant-progress-text {
        font-size: 0.875rem !important;
      }
      vertical-align: top;
    }
    span:nth-of-type(2) {
      display: inline-block;
      width: 95%;
      @media (min-width: 1150px) {
        width: 65%;
      }
    }
  `,
  topMargin: css`
    margin-top: 1.875rem;
  `,
};
