import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  clickableCard: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: 0 0.625rem 0.625rem 0;
    margin-bottom: 0.938rem;
    height: 8.75rem;
    width: 13.75rem;
    border: 0.063rem solid ${colors.gray.a20};
    background: ${colors.white};
    text-align: left;
    padding: 1.25rem 3.125rem 1.25rem 1.25rem;
    box-sizing: border-box;
    border-radius: 0.375rem;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    z-index: 1;

    .stat {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.875rem;
      color: ${colors.black};
      margin: 0.625rem 0;
    }

    &:focus {
      border: 2px solid ${colors.blue.dark};
      color: ${colors.gray.veryDark};
      font-weight: 600;

      .stat {
        color: ${colors.blue.dark};
      }
    }

    &:hover {
      cursor: pointer;
      background: ${colors.gray.a5};
    }
  `,
  info: css`
    position: absolute;
    top: 0;
  `,
  title: css`
    color: ${colors.gray.dark};
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.75rem;
  `,
  stat: css`
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.875;
    color: ${colors.black};
    margin: 0.625rem 0;
  `,
};
