import { Card, Progress, Table } from "antd";
import React, { ReactElement } from "react";
import { AudienceInterest } from "src/api/types/artist-audience-breakdown";
import { ColumnsType } from "antd/es/table";
import colors from "src/colors";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import styles from "../styles";

interface IProps {
  audienceInterests: AudienceInterest[] | undefined;
}

interface Interests {
  rank: number;
  name: string;
  percent: EmotionJSX.Element;
}

const InterestsCard = ({ audienceInterests }: IProps): ReactElement => {
  let interestBreakdown = null;
  if (audienceInterests) {
    const data = audienceInterests.map((d) => {
      return {
        rank: audienceInterests.indexOf(d) + 1,
        name: d.name,
        percent: (
          <div css={{ width: 100 }}>
            <Progress
              strokeColor={colors.purple.dark}
              strokeWidth={12}
              percent={Number(d.percent)}
            />
          </div>
        ),
      };
    });
    const columns: ColumnsType<Interests> = [
      {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
      },
      {
        title: "Interests",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Percent Interested",
        dataIndex: "percent",
        key: "percent",
        align: "right",
      },
    ];
    interestBreakdown = (
      <Card css={styles.smallCard}>
        <h4>INTERESTS</h4>
        <Table
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "grayrow")}
          size="small"
          columns={columns}
          dataSource={data}
        />
      </Card>
    );
  }
  return <>{interestBreakdown}</>;
};

export default InterestsCard;
