import React, { ReactElement, useState } from "react";
import { Card } from "antd";
import DropdownSelector from "src/components/dropdown-selector";
import {
  ArtistAudienceBreakdown,
  AudienceDetails,
} from "src/api/types/artist-audience-breakdown";
import abbreviateNumber from "src/utils/helpers";
import styles from "../../pages/artist-landing-page/styles";
import cardStyles from "./styles";

interface IProps {
  audienceDetails: ArtistAudienceBreakdown | null;
}

const EngagementCard = ({ audienceDetails }: IProps): ReactElement => {
  const [audience, setAudience] = useState<AudienceDetails | undefined>(
    audienceDetails?.instagramAudience
  );

  const dropDownOptions = ["Instagram", "TikTok", "YouTube"];

  const dropdownHandler = (option: string) => {
    switch (option) {
      case "Instagram":
        setAudience(audienceDetails?.instagramAudience);
        break;
      case "TikTok":
        setAudience(audienceDetails?.tikTokAudience);
        break;
      case "YouTube":
        setAudience(audienceDetails?.youTubeAudience);
        break;
      default:
        setAudience(audienceDetails?.instagramAudience);
        break;
    }
  };

  return (
    <div>
      <Card css={styles.card}>
        <h3>ENGAGEMENT</h3>
        <div css={cardStyles.select}>
          <DropdownSelector
            data-heap-id="artist-engagement-platform-select"
            dropdownOptions={dropDownOptions}
            selectHandler={dropdownHandler}
          />
        </div>
        <div css={styles.panelCardGroup}>
          <Card css={cardStyles.card}>
            <div css={cardStyles.info}>
              <h4 css={cardStyles.title}>Engagement Rate</h4>
              <p css={cardStyles.stat}>
                {audience?.engagementRate &&
                  audience?.engagementRate.toFixed(2)}
              </p>
            </div>
          </Card>
          <Card css={cardStyles.card}>
            <div css={cardStyles.info}>
              <h4 css={cardStyles.title}>Avg Likes Per Post</h4>
              <p css={cardStyles.stat}>
                {audience?.averageLikesPerPost &&
                  abbreviateNumber(audience.averageLikesPerPost, 2)}
              </p>
            </div>
          </Card>
          {/* <Card css={cardStyles.card}>
            <h4>Avg Comments Per Post</h4>
            <p>{audience?.averegeCommentsPerPost}</p>
          </Card> to be added when this data is being returned from the back end */}
        </div>
      </Card>
    </div>
  );
};

export default EngagementCard;
