import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  card: css`
    margin-top: 1rem;
  `,
  twoCardLayout: css`
    display: flex;
    justify-content: space-between;
  `,
  smallCard: css`
    flex: 1;
    margin: 1rem;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    line-height: 18px;

    .ant-table-thead > tr > th {
      background: ${colors.gray.tableHeader} !important;
    }

    .table-row-gray {
      background: ${colors.gray.veryLight};
    }
  `,
};
