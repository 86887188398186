export function pitchClassToString(key: number | undefined): string {
  if (!key) {
    return "";
  }

  switch (key) {
    case 0:
      return "0 (C)";
    case 1:
      return "1 (C♯ D♭)";
    case 2:
      return "2 (D)";
    case 3:
      return "3 (D♯, E♭)";
    case 4:
      return "4 (E)";
    case 5:
      return "5 (F)";
    case 6:
      return "6 (F♯, G♭)";
    case 7:
      return "7 (G)";
    case 8:
      return "8 (G♯, A♭)";
    case 9:
      return "9 (A)";
    case 10:
      return "10 (A♯, B♭)";
    case 11:
      return "11 (B)";
    default:
      return key.toString();
  }
}
