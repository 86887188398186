import { css } from "@emotion/react";

export default {
  resultsTable: css`
    margin-bottom: 4rem;
    table {
      font-family: proxima-nova, sans-serif;
      border-collapse: separate;
      border-spacing: 0 0.625rem;
      background: #f7f7f7;
      thead {
        th {
          background: #f7f7f7;
          border: none;
          font-size: 1rem;
          font-weight: 600;
        }
        th::before {
          background-color: transparent !important;
        }
      }
      tbody {
        td {
          color: black;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          background: white;
          a {
            color: black;
          }
        }
        tr:hover > td {
          background: #e8e8e8 !important;
          cursor: pointer;
        }
        tr td:first-child,
        tr td:last-child {
          border-radius: 0.25rem !important;
        }
      }
    }
  `,
  thumbnail: css`
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 0.25rem;
  `,
  loading: css`
    margin-bottom: 6rem;
  `,
  title: css`
    margin-left: 3.5rem;
    margin-top: -2.5rem;
  `,
};
