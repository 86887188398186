import React, { Fragment, ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "src/components/loading-spinner";
import { ISearchOption } from "src/interfaces/search";
import sectionStyles from "./styles";

interface IProps {
  songResults: ISearchOption[];
  artistResults: ISearchOption[];
  isLoading: boolean;
  searchTerm: string;
}

export default function AutoSuggest({
  songResults,
  artistResults,
  isLoading,
  searchTerm,
}: IProps): ReactElement | null {
  const history = useHistory();

  const handleClick = (link: string) => {
    history.push(link);
  };

  useEffect(() => {
    const topTracks = document.querySelector(".top-tracks-wrapper");
    if (searchTerm.length > 0) {
      if (topTracks) {
        topTracks.classList.add("results-focus");
      }
    } else if (topTracks) {
      topTracks.classList.remove("results-focus");
    }
  }, [searchTerm]);

  useEffect(() => {
    return () => {
      const topTracks = document.querySelector(".top-tracks-wrapper");
      topTracks?.classList.remove("results-focus");
    };
  }, []);

  if (searchTerm.length === 0) return null;

  return (
    <Fragment>
      {songResults.length > 0 && !isLoading && (
        <section css={sectionStyles.results}>
          <div className="song-list">
            <h2>Songs</h2>
            <ul>
              {songResults.map((song) => {
                return (
                  <li>
                    <button
                      data-heap-id="song-search-result"
                      data-selected-song={song.label}
                      type="button"
                      onClick={() => {
                        handleClick(song.value);
                      }}
                      onKeyDown={() => {
                        handleClick(song.value);
                      }}
                    >
                      {song.label}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="artist-list">
            <h2>Artists</h2>
            <ul>
              {artistResults.map((artist) => {
                return (
                  <li>
                    <button
                      data-heap-id="artist-search-result"
                      data-selected-artist={artist.label}
                      type="button"
                      onClick={() => handleClick(artist.value)}
                      onKeyDown={() => handleClick(artist.value)}
                    >
                      {artist.label}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      )}
      {isLoading && <LoadingSpinner />}
      {songResults.length === 0 && searchTerm.length > 0 && !isLoading && (
        <div css={sectionStyles.notFound}>
          <h4>Sorry, we couldn&apos;t find a song with that title.</h4>
          <p>
            Please try again with less or different keywords. Otherwise, click
            &quot;Reach out to us&quot; below and send us a message.
          </p>
        </div>
      )}
    </Fragment>
  );
}
