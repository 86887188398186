import React, { ReactElement, useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import PageLoading from "src/components/page-loading";
import { getArtistLicenseEstimationDetails, getTrackBase } from "src/api";
import {
  TrackBaseMetadata,
  TrackSpotifyDetails,
} from "src/api/types/track-base-metadata";
import Navbar from "src/components/nav-bar";
import { InlineSpotifyPlayer } from "src/components/spotify-player";
import TrackLandingPage from "..";

interface RouteParams {
  trackId: string;
}

const formatPageTitle = (track: TrackSpotifyDetails) => {
  const artistArray = track.artists.map((artist) => {
    return artist.name;
  });
  return `${track.title} by ${artistArray.join(", ")}`;
};

export default function TrackLandingContainer(): ReactElement {
  const { trackId } = useParams<RouteParams>();

  const [isLoading, setIsLoading] = useState(true);
  const [trackBase, setTrackBase] = useState<TrackBaseMetadata | null>(null);
  const [pageTitle, setPageTitle] = useState("");
  const [isrc, setIsrc] = useState<string | undefined>("");
  const [artistRank, setArtistRank] = useState<number | undefined>();

  useEffect(() => {
    getTrackBase(trackId)
      .then((result) => {
        setPageTitle(formatPageTitle(result.spotify));
        setIsLoading(false);
        setTrackBase(result);
        setIsrc(result?.spotify.isrc || result?.analysis?.isrc);
      })
      .catch((err: Error) => {
        throw new Error(`Error retrieving track base: ${err.message}`);
      });
  }, [trackId]);

  const chartmetricArtist =
    trackBase?.analysis?.artists && trackBase?.analysis?.artists[0];
  const artistChartmetricId = chartmetricArtist?.id;

  useEffect(() => {
    if (artistChartmetricId) {
      setArtistRank(-1);
      getArtistLicenseEstimationDetails(artistChartmetricId)
        .then((result) => {
          setArtistRank(result.rank);
        })
        .catch((err: Error) => {
          setArtistRank(undefined);
          // eslint-disable-next-line no-console
          console.warn(
            "Error retrieving artist licensing estimate metrics",
            err
          );
        });
    }
  }, [artistChartmetricId]);

  if (isLoading || trackBase === null) {
    return <PageLoading />;
  }

  return (
    <Fragment>
      <Navbar title={pageTitle} />
      <TrackLandingPage
        trackBase={trackBase}
        trackId={trackId}
        isrc={isrc}
        artistRank={artistRank}
      />
      <InlineSpotifyPlayer trackId={trackId} />
    </Fragment>
  );
}
