import React, { ReactElement } from "react";
import { Card } from "antd";
import { Pie } from "@ant-design/charts";
import colors from "src/colors";
import styles from "../styles";

interface Props {
  // Getting the type to work here was a pain, so using the any hammer for the time being.
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data?: any[];
  title?: string;
  angleField: string;
  colorField: string;
  useInnerLabels?: boolean;
  addPercentSign?: boolean;
}

interface PieData {
  share: number;
  color: string;
}

interface PieConfig {
  angleField: string;
  colorField: string;
  data: PieData[];
  label?: {
    type?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content?: (d: Record<string, any>) => string;
  };
  radius?: number;
  height?: number;
  title?: string;
  color?: string[];
}

export default function AudienceBreakdownPieChart({
  data,
  angleField,
  colorField,
  addPercentSign,
  useInnerLabels,
  title,
}: Props): ReactElement {
  if (!data) {
    return <></>;
  }

  const restructured = data.map((g) => {
    return {
      share: parseFloat(g[angleField].toString()),
      color: g[colorField].toString(),
    };
  });

  const genderBreakdownGraphConfig: PieConfig = {
    angleField: "share",
    colorField: "color",
    radius: 0.8,
    height: 250,
    color: [colors.purple.dark, colors.blue.light],
    data: restructured,
  };
  if (useInnerLabels) {
    genderBreakdownGraphConfig.label = genderBreakdownGraphConfig.label || {};
    genderBreakdownGraphConfig.label.type = "inner";
  }
  if (addPercentSign) {
    genderBreakdownGraphConfig.label = genderBreakdownGraphConfig.label || {};
    genderBreakdownGraphConfig.label.content = function addPercent(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      d: Record<string, any>
    ) {
      return "".concat(d.share.toFixed(0), "%");
    };
  }
  return (
    <Card css={styles.smallCard}>
      <h4>{title}</h4>
      <Pie {...genderBreakdownGraphConfig} />
    </Card>
  );
}
