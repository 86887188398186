import React, { ReactElement, useEffect, useState } from "react";
import { getTrackCredits } from "src/api";
import { TrackBaseMetadata } from "src/api/types/track-base-metadata";
import { TrackCreditsDetails } from "src/api/types/track-credits";
import SongDetails from "..";

interface Props {
  trackBase: TrackBaseMetadata;
  isrc?: string;
}

export default function SongDetailsContainer({
  trackBase,
  isrc,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(true);
  const [ownership, setOwnership] = useState<TrackCreditsDetails | undefined>();

  const fetchCredits = (isrcValue: string) => {
    setIsLoading(true);
    getTrackCredits(isrcValue)
      .then((results) => {
        setIsLoading(false);
        setOwnership(results);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  };

  useEffect(() => {
    if (isrc) {
      fetchCredits(isrc);
    }
  }, [isrc]);

  return (
    <SongDetails
      trackBase={trackBase}
      ownership={ownership}
      ownershipLoading={isLoading}
    />
  );
}
