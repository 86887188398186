import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  card: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: 0 0.625rem 0.625rem 0;
    margin-bottom: 0.938rem;
    margin-left: -1.25rem;
    height: 8.75rem;
    width: 14.75rem;
    border: 0;
    background: ${colors.white};
    text-align: left;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
  `,
  info: css`
    position: absolute;
    top: 0;
  `,
  title: css`
    color: ${colors.gray.dark};
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.75rem;
  `,
  stat: css`
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.875;
    color: ${colors.black};
    margin: 0.625rem 0;
  `,
};
