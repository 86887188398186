import React, { ReactElement, useState } from "react";
import { Card } from "antd";
import { ArtistBaseMetadata } from "src/api/types/artist-base-metadata";
import moment from "moment";
import abbreviateNumber from "src/utils/helpers";
import ArtistStatGraph from "../../artist-stat-graph";
import { ArtistStats } from "../../../api/types/artist-stats";
import ClickableCard from "../../clickable-card";
import DropdownSelector from "../../dropdown-selector";
import styles from "../../pages/artist-landing-page/styles";

interface IProps {
  artistBase: ArtistBaseMetadata;
  until: moment.Moment;
  dropdownOptions: string[];
  chartmetricId?: number;
}

const FanbaseGrowthCard = ({
  artistBase,
  until,
  dropdownOptions,
  chartmetricId,
}: IProps): ReactElement => {
  const [displayType, setDisplayType] = useState<string>("instagram");
  const [since, setSince] = useState<moment.Moment>(
    moment().subtract(1, "year")
  );
  const [dateFormat, setDateformat] = useState<string>("ll");
  const [selectedOption, setSelectedOption] = useState<string>("Past 1 Year");

  const selectTimeRangeHandler = (option: string) => {
    switch (option) {
      case "Past 1 Month":
        setSince(moment().subtract(1, "month"));
        setDateformat("MMM D");
        setSelectedOption("Past 1 Month");
        break;
      case "Past 3 Months":
        setSince(moment().subtract(3, "months"));
        setDateformat("ll");
        setSelectedOption("Past 3 Months");
        break;
      case "Past 6 Months":
        setSince(moment().subtract(6, "months"));
        setDateformat("ll");
        setSelectedOption("Past 6 Months");
        break;
      case "Past 1 Year":
        setSince(moment().subtract(1, "year"));
        setDateformat("ll");
        setSelectedOption("Past 1 Year");
        break;
      case "All Time":
        setSince(moment(new Date(0)));
        setDateformat("MMM Y");
        setSelectedOption("All Time");
        break;
      default:
        setDateformat("ll");
    }
  };

  const displayHandler = (type: string) => {
    setDisplayType(type);
  };

  return (
    <div>
      <Card css={styles.card}>
        <h3>FANBASE GROWTH</h3>
        <div css={styles.panelCardGroup}>
          <ClickableCard
            data-heap-id="artist-instagram-followers"
            title="Instagram Followers"
            type="instagram"
            handler={displayHandler}
            displayStat={
              artistBase.analysis?.statistics?.instagramFollowers &&
              abbreviateNumber(
                artistBase.analysis?.statistics?.instagramFollowers,
                2
              )
            }
            className={displayType === "instagram" ? "initialFocused" : ""}
          />
          <ClickableCard
            data-heap-id="artist-tiktok-followers"
            title="TikTok Followers"
            type="tiktok"
            handler={displayHandler}
            displayStat={
              artistBase.analysis?.statistics?.tiktokFollowers &&
              abbreviateNumber(
                parseInt(artistBase.analysis?.statistics?.tiktokFollowers, 10),
                2
              )
            }
            className={displayType === "tiktok" ? "initialFocused" : ""}
          />
          <ClickableCard
            data-heap-id="artist-youtube-followers"
            title="YouTube Followers"
            type="youtube"
            handler={displayHandler}
            displayStat={
              artistBase.analysis?.statistics?.youtubeChannelSubscribers &&
              abbreviateNumber(
                artistBase.analysis?.statistics?.youtubeChannelSubscribers,
                2
              )
            }
            className={displayType === "youtube" ? "initialFocused" : ""}
          />
        </div>
        <DropdownSelector
          data-heap-id="artist-social-duration-select"
          dropdownOptions={dropdownOptions}
          selectHandler={selectTimeRangeHandler}
        />
        {displayType === "instagram" && (
          <ArtistStatGraph
            title="Instagram Followers"
            stat={ArtistStats.instagramFollowers}
            chartmetricId={chartmetricId}
            displayStat={
              artistBase.analysis?.statistics?.instagramFollowers &&
              abbreviateNumber(
                artistBase.analysis?.statistics?.instagramFollowers,
                2
              )
            }
            dateFormat={dateFormat}
            selectedOption={selectedOption}
            since={since}
            until={until}
          />
        )}
        {displayType === "tiktok" && (
          <ArtistStatGraph
            title="TikTok Followers"
            stat={ArtistStats.tiktokFollowers}
            chartmetricId={chartmetricId}
            displayStat={
              artistBase.analysis?.statistics?.tiktokFollowers &&
              abbreviateNumber(
                parseInt(artistBase.analysis?.statistics?.tiktokFollowers, 10),
                2
              )
            }
            dateFormat={dateFormat}
            selectedOption={selectedOption}
            since={since}
            until={until}
          />
        )}

        {displayType === "youtube" && (
          <ArtistStatGraph
            title="YouTube Followers"
            stat={ArtistStats.youtubeSubscribers}
            chartmetricId={chartmetricId}
            displayStat={
              artistBase.analysis?.statistics?.youtubeChannelSubscribers &&
              abbreviateNumber(
                artistBase.analysis?.statistics?.youtubeChannelSubscribers,
                2
              )
            }
            dateFormat={dateFormat}
            selectedOption={selectedOption}
            since={since}
            until={until}
          />
        )}
      </Card>
    </div>
  );
};

export default FanbaseGrowthCard;
