import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  cardContainer: css`
    margin: 3rem;
    h3 {
      font-family: proxima-nova, sans-serif;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.125rem;
      letter-spacing: 0.094rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    h2 {
      margin-top: 2rem;
    }

    .initialFocused {
      border: 2px solid ${colors.blue.dark} !important;
      color: ${colors.gray.veryDark};
      font-weight: 600;

      .stat {
        color: ${colors.blue.dark} !important;
      }
    }
  `,
  panelCardGroup: css`
    display: flex;
  `,
  card: css`
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 4px;
  `,
  toolTipIcon: css`
    color: #bcccdc;
    width: 1.25rem !important;
    height: 1.25rem !important;
    cursor: pointer;
  `,
};
