declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_SETTINGS: "development" | "production" | "staging";
      REACT_APP_LOCAL_SERVICE: boolean;
    }
  }
}

const heapAnalyticsDomains = [
  "https://cdn.heapanalytics.com",
  "https://heapanalytics.com",
];

const devApiBaseUrl = process.env.REACT_APP_LOCAL_SERVICE
  ? "http://127.0.0.1:4060"
  : "https://search.api.dev.songtradr.dev";

const config = {
  production: {
    analytics: {
      heapAppID: "3919371772",
      heapDomains: heapAnalyticsDomains,
    },
    production: true,
    apiBaseUrl: "https://search.api.prod1.songtradr.net",
  },
  staging: {
    apiBaseUrl: "https://search.api.staging-2.songtradr.dev",
    analytics: {
      heapAppID: "3306784084",
      heapDomains: heapAnalyticsDomains,
    },
  },
  development: {
    apiBaseUrl: devApiBaseUrl,
    analytics: {
      heapAppID: "3306784084",
      heapDomains: heapAnalyticsDomains,
    },
  },
};

export default config[process.env.REACT_APP_SETTINGS] || config.development;
