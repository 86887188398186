import React, { ReactElement } from "react";
import { SearchTrack } from "src/api/types/search-results";
import ArtistTopTracksTable from "./artist-top-tracks-table";
import styles from "./styles";

interface IProps {
  topTracks: SearchTrack[] | undefined;
}

const ArtistTopTracksPanel = ({ topTracks }: IProps): ReactElement => {
  return (
    <div css={styles.wrapper}>
      <h2 id="top-tracks" className="landing-anchor">
        Top Tracks
      </h2>
      <ArtistTopTracksTable tracks={topTracks} />
    </div>
  );
};

export default ArtistTopTracksPanel;
