import React, { ReactElement, useState } from "react";
import { ArtistAudienceBreakdown } from "src/api/types/artist-audience-breakdown";
import cities from "cities.json";
import { Card } from "antd";
import LoadingSpinner from "../loading-spinner";
import BubbleMap, { IMapCity } from "../bubble-map";
import styles from "./styles";
import ArtistLocationTable from "../artist-locations-table";

const citiesData = JSON.parse(JSON.stringify(cities));

interface IProps {
  audienceDetails: ArtistAudienceBreakdown | null;
}

interface ICity {
  country: string;
  name: string;
  lat: string;
  lng: string;
}

const formatSpotifyMapData = (
  audienceDetails: ArtistAudienceBreakdown
): IMapCity[] | [] => {
  const mapData = audienceDetails?.spotifyWherePeopleListen?.cities
    .filter((spotifyCity) => {
      return (
        spotifyCity.name &&
        spotifyCity.countryCode &&
        spotifyCity.listeners &&
        spotifyCity.countryCode
      );
    })
    .map((spotifyCity) => {
      const coordinates = citiesData.find(
        (city: ICity) =>
          city.name === spotifyCity.name &&
          city.country === spotifyCity.countryCode
      );

      if (!coordinates) {
        return {
          name: spotifyCity.name,
          code: spotifyCity.countryCode,
          listeners: spotifyCity.listeners,
        };
      }

      return {
        name: spotifyCity.name,
        code: spotifyCity.countryCode,
        listeners: spotifyCity.listeners,
        coordinates: [coordinates.lat, coordinates.lng],
      } as IMapCity;
    });

  if (!mapData) {
    return [];
  }
  return mapData;
};

const formatInstagramMapData = (
  audienceDetails: ArtistAudienceBreakdown
): IMapCity[] | [] => {
  if (!audienceDetails?.instagramAudience?.topCities) {
    return [];
  }
  const mapData = audienceDetails?.instagramAudience?.topCities
    .filter((instaCity) => {
      return instaCity.name && instaCity.country && instaCity.followers;
    })
    .map((instaCity) => {
      const coordinates = citiesData.find(
        (city: ICity) => city.name === instaCity.name
      );

      if (!coordinates) {
        return {
          name: instaCity.name,
          listeners: instaCity.followers,
        };
      }

      return {
        name: instaCity.name,
        listeners: instaCity.followers,
        coordinates: [coordinates.lat, coordinates.lng],
      } as IMapCity;
    });

  if (!mapData) {
    return [];
  }
  return mapData;
};

const formatMapData = (
  selectedPlatform: string,
  audienceDetails: ArtistAudienceBreakdown
) => {
  switch (selectedPlatform) {
    case "Spotify":
      return formatSpotifyMapData(audienceDetails);
    case "Instagram":
      return formatInstagramMapData(audienceDetails);
    default:
      return [];
  }
};

const ArtistLocationPanel = ({ audienceDetails }: IProps): ReactElement => {
  const [selectedPlatform, setSelectedPlatform] = useState("Spotify");
  if (!audienceDetails) {
    return <LoadingSpinner />;
  }

  const selectHandler = (value: string) => {
    setSelectedPlatform(value);
  };

  const data = formatMapData(selectedPlatform, audienceDetails);
  const mapConfig = {
    minLat: -6.1751,
    maxLat: 55.7558,
    minLong: 37.6173,
    maxLong: 139.6917,
  };

  return (
    <Card css={styles.locationPanel}>
      <h3>Top Locations</h3>
      <div css={styles.flexbox}>
        <ArtistLocationTable
          audienceDetails={audienceDetails}
          selectHandler={selectHandler}
          selectedPlatform={selectedPlatform}
        />
        <BubbleMap data={data} {...mapConfig} />
      </div>
    </Card>
  );
};

export default ArtistLocationPanel;
