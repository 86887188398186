import { Dispatch, SetStateAction, useState } from "react";

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      const value = item ? JSON.parse(item) : initialValue;
      return value as T;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(
        `Error trying to load useLocalStorage item. Key: ${key} Error:`,
        error
      );
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(
        `Error trying to set value in useLocalStorage. Key: ${key} Error:`,
        error
      );
    }
  };
  return [storedValue, setValue];
}
