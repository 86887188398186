import React, { ReactElement } from "react";
import styles from "./styles";

interface IProps {
  title: string;
  type: string;
  displayStat: number | string | undefined;
  handler: (type: string) => void;
  className?: string;
}

const ClickableCard = ({
  handler,
  title,
  type,
  displayStat,
  className,
}: IProps): ReactElement => {
  return (
    <button
      onClick={() => handler(type)}
      type="button"
      css={styles.clickableCard}
      className={className}
    >
      <div css={styles.info}>
        <p css={styles.title}>{title}</p>
        <p className="stat">{displayStat}</p>
      </div>
    </button>
  );
};

export default ClickableCard;
