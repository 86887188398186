import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  chartTitle: css`
    display: inline-block;
    font-family: proxima-nova, sans-serif;
    font-size: 1.25rem;
    margin-bottom: 4px;
  `,
  dateRange: css`
    font-family: proxima-nova, sans-serif;
    font-size: 0.875rem;
    color: ${colors.blue.gray};
    margin-bottom: 3.5625rem;
    margin-top: 4px;
  `,
  displayStat: css`
    font-family: proxima-nova, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: ${colors.blue.dark};
    margin-bottom: 4px;
    margin-top: 4px;
  `,
  toolTip: css`
    display: inline-block;
    margin-left: 1rem;
  `,
};
