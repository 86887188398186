import React, { ReactElement, useState } from "react";
import { Card, Tooltip } from "antd";
import { ArtistBaseMetadata } from "src/api/types/artist-base-metadata";
import moment from "moment";
import abbreviateNumber from "src/utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ArtistStatGraph from "../artist-stat-graph";
import { ArtistStats } from "../../api/types/artist-stats";
import ClickableCard from "../clickable-card";
import DropdownSelector from "../dropdown-selector";
import styles from "../pages/artist-landing-page/styles";

interface IProps {
  artistBase: ArtistBaseMetadata;
  until: moment.Moment;
  dropdownOptions: string[];
}

export const spotifyPopularityTooltip = (
  <Tooltip
    title="Ranks a track against all other music on a streaming platform. 
  The ranking is a 0 to 100 score that influences all aspects of Spotify’s discovery, 
  recommendation, and algorithmic playlist generation."
  >
    <FontAwesomeIcon css={styles.toolTipIcon} icon={faInfoCircle} />
  </Tooltip>
);

const ArtistSpotifyPanel = ({
  artistBase,
  until,
  dropdownOptions,
}: IProps): ReactElement => {
  const chartmetricId = artistBase?.analysis?.ids.chartmetricIds[0];
  const [displayType, setDisplayType] = useState<string>("popularity");
  const [since, setSince] = useState<moment.Moment>(
    moment().subtract(1, "year")
  );
  const [dateFormat, setDateformat] = useState<string>("ll");
  const [selectedOption, setSelectedOption] = useState<string>("Past 1 Year");

  const displayHandler = (type: string) => {
    setDisplayType(type);
  };

  const selectHandler = (option: string) => {
    switch (option) {
      case "Past 1 Month":
        setSince(moment().subtract(1, "month"));
        setDateformat("MMM D");
        setSelectedOption("Past 1 Month");
        break;
      case "Past 3 Months":
        setSince(moment().subtract(3, "months"));
        setDateformat("ll");
        setSelectedOption("Past 3 Months");
        break;
      case "Past 6 Months":
        setSince(moment().subtract(6, "months"));
        setDateformat("ll");
        setSelectedOption("Past 6 Months");
        break;
      case "Past 1 Year":
        setSince(moment().subtract(1, "year"));
        setDateformat("ll");
        setSelectedOption("Past 1 Year");
        break;
      case "All Time":
        setSince(moment(new Date(0)));
        setDateformat("MMM Y");
        setSelectedOption("All Time");
        break;
      default:
        setDateformat("ll");
    }
  };

  return (
    <>
      <h2 className="landing-anchor" id="spotify">
        Spotify
      </h2>
      <div>
        <Card css={styles.card}>
          <div css={styles.panelCardGroup}>
            <ClickableCard
              data-heap-id="artist-spotify-popularity"
              title="Spotify Popularity"
              type="popularity"
              handler={displayHandler}
              displayStat={artistBase.spotify.popularity}
              className={displayType === "popularity" ? "initialFocused" : ""}
            />
            <ClickableCard
              data-heap-id="artist-spotify-monthly-listeners"
              title="Spotify Monthly Listeners"
              type="listeners"
              handler={displayHandler}
              displayStat={
                artistBase.analysis?.statistics?.spotifyMonthlyListeners &&
                abbreviateNumber(
                  artistBase.analysis?.statistics?.spotifyMonthlyListeners,
                  2
                )
              }
              className={displayType === "listeners" ? "initialFocused" : ""}
            />
            <ClickableCard
              data-heap-id="artist-spotify-followers"
              title="Spotify Followers"
              type="followers"
              handler={displayHandler}
              displayStat={
                artistBase.analysis?.statistics?.spotifyFollowers &&
                abbreviateNumber(
                  artistBase.analysis?.statistics?.spotifyFollowers,
                  2
                )
              }
              className={displayType === "followers" ? "initialFocused" : ""}
            />
          </div>
          <DropdownSelector
            data-heap-id="artist-spotify-duration-select"
            dropdownOptions={dropdownOptions}
            selectHandler={selectHandler}
          />
          {displayType === "popularity" && (
            <ArtistStatGraph
              title="Spotify Popularity"
              stat={ArtistStats.spotifyPopularity}
              chartmetricId={chartmetricId}
              displayStat={artistBase.spotify.popularity}
              dateFormat={dateFormat}
              selectedOption={selectedOption}
              since={since}
              until={until}
              tooltip={spotifyPopularityTooltip}
            />
          )}
          {displayType === "listeners" && (
            <ArtistStatGraph
              title="Spotify Monthly Listeners"
              stat={ArtistStats.spotifyListeners}
              chartmetricId={chartmetricId}
              displayStat={
                artistBase.analysis?.statistics?.spotifyMonthlyListeners &&
                abbreviateNumber(
                  artistBase.analysis?.statistics?.spotifyMonthlyListeners,
                  2
                )
              }
              dateFormat={dateFormat}
              selectedOption={selectedOption}
              since={since}
              until={until}
            />
          )}

          {displayType === "followers" && (
            <ArtistStatGraph
              title="Spotify Followers"
              stat={ArtistStats.spotifyFollowers}
              chartmetricId={chartmetricId}
              displayStat={
                artistBase.analysis?.statistics?.spotifyFollowers &&
                abbreviateNumber(
                  artistBase.analysis?.statistics?.spotifyFollowers,
                  2
                )
              }
              dateFormat={dateFormat}
              selectedOption={selectedOption}
              since={since}
              until={until}
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default ArtistSpotifyPanel;
