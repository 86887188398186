import { css } from "@emotion/react";

export default {
  lyrics: css`
    white-space: pre-line;
  `,
  expandIcon: css`
    position: sticky;
    float: right;
    top: 1.5625rem;
    margin-top: -3.125rem;
    cursor: pointer;
  `,
};
