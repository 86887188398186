import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "src/components/nav-bar";
import mockData from "../mockData";
import SearchResults from "..";
import { ISearchOptions, ISearchResult } from "../interface";

const apiMock = async () => new Promise((resolve) => setTimeout(resolve, 1000));

export default function SearchResultsContainer(): ReactElement {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchOptions, setSearchOptions] = useState<ISearchOptions>({
    exact: "",
    any: [],
    none: [],
  });
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setSearchOptions({
      exact: query.get("exact"),
      any: query.getAll("any"),
      none: query.getAll("none"),
    });
    setIsLoading(true);
    /* TODO: Replace with endpoint when available */
    apiMock()
      .then(() => {
        setSearchResults(mockData);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [location]);

  return (
    <Fragment>
      <Navbar title="Lyric Search" />
      <SearchResults
        searchOptions={searchOptions}
        searchResults={searchResults}
        isLoading={isLoading}
        isError={isError}
      />
    </Fragment>
  );
}
