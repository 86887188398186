import React, { ReactElement, useEffect, useState } from "react";
import { TrackLicensingHistory } from "../../api/types/track-licensing-history";
import TrackLicensingDisplay from "./index";
import { getTrackLicensingHistory } from "../../api";

interface Props {
  spotifyId: string;
}

export default function TrackLicensingLoader({
  spotifyId,
}: Props): ReactElement {
  const [licensingHistory, setLicensingHistory] = useState<
    TrackLicensingHistory | undefined
  >();

  useEffect(() => {
    if (!spotifyId) {
      return;
    }

    getTrackLicensingHistory(spotifyId)
      .then((results) => {
        setLicensingHistory(results);
      })
      .catch((err) => {
        throw err;
      });
  }, [spotifyId]);

  return <TrackLicensingDisplay data={licensingHistory} />;
}
