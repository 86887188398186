import { css, keyframes } from "@emotion/react";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export default {
  loadingContainer: css`
    padding-top: 2rem;
  `,
  loading: css`
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid #e8e8e8;
    border-right: 2px solid #e8e8e8;
    border-bottom: 2px solid #e8e8e8;
    border-left: 2px solid #1f48e7;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0 auto;
  `,
};
