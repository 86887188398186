import React, { ReactElement, useEffect, useState } from "react";
import { Space } from "antd";
import { ArtistBaseMetadata } from "src/api/types/artist-base-metadata";
import ArtistHeader from "src/components/artist-header";
import ArtistSpotifyPanel from "src/components/artist-spotify-panel";
import ArtistSocialMediaPanel from "src/components/artist-social-media-panel";
import moment from "moment";
import { getArtistAudienceDetails } from "src/api";
import { ArtistAudienceBreakdown } from "src/api/types/artist-audience-breakdown";
import ArtistAudiencePanel from "src/components/artist-audience-panel";
import ArtistLocationPanel from "src/components/artist-location-panel";
import BrandAffinityAndInterests from "src/components/brand-affinity-and-interests-panel";
import LicenseHistoryPanel from "src/components/license-history-panel";
import ArtistTopTracksPanel from "src/components/artist-top-tracks-panel";
import colors from "src/colors";
import styles from "./styles";

interface IProps {
  artistBase: ArtistBaseMetadata;
  artistId: string;
}

export default function ArtistLandingPage({
  artistBase,
  artistId,
}: IProps): ReactElement {
  const [audienceDetails, setAudienceDetails] =
    useState<ArtistAudienceBreakdown | null>(null);
  const chartmetricId = artistBase?.analysis?.ids.chartmetricIds[0];

  const dropdownOptions = [
    "Past 1 Year",
    "Past 1 Month",
    "Past 3 Months",
    "Past 6 Months",
    "All Time",
  ];

  const until = moment();

  useEffect(() => {
    if (!chartmetricId) {
      return;
    }

    getArtistAudienceDetails(chartmetricId)
      .then((results) => {
        setAudienceDetails(results);
      })
      .catch((err) => {
        throw err;
      });
  }, [chartmetricId]);

  return (
    <Space
      direction="vertical"
      css={{ width: "100%", background: colors.gray.background }}
    >
      <ArtistHeader
        image={artistBase.spotify.thumbnailUrl}
        name={artistBase.spotify.name}
        genres={artistBase.spotify.genres}
      />
      <div css={styles.cardContainer}>
        <ArtistSpotifyPanel
          artistBase={artistBase}
          until={until}
          dropdownOptions={dropdownOptions}
        />
        <ArtistSocialMediaPanel
          artistBase={artistBase}
          until={until}
          dropdownOptions={dropdownOptions}
          audienceDetails={audienceDetails}
        />
        <ArtistAudiencePanel audienceDetails={audienceDetails} />
        <ArtistLocationPanel audienceDetails={audienceDetails} />
        <BrandAffinityAndInterests audienceDetails={audienceDetails} />
        <LicenseHistoryPanel
          spotifyId={artistId}
          artistImage={artistBase.spotify.thumbnailUrl}
        />
        <ArtistTopTracksPanel topTracks={artistBase.spotify.topTracks} />
      </div>
    </Space>
  );
}
