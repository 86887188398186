import React, { ReactElement, useState } from "react";
import { Card } from "antd";
import moment from "moment";
import { TrackBaseMetadata } from "src/api/types/track-base-metadata";
import { TrackStats } from "src/api/types/track-stats";
import abbreviateNumber from "src/utils/helpers";
import TrackStatGraph from "../track-stat-graph";
import styles from "./styles";
import ClickableCard from "../clickable-card";
import DropdownSelector from "../dropdown-selector";

interface IProps {
  trackBase: TrackBaseMetadata;
  chartmetricId?: number;
}

const TrackSocialPanel = ({
  trackBase,
  chartmetricId,
}: IProps): ReactElement => {
  const [since, setSince] = useState<moment.Moment | undefined>(
    moment().subtract(1, "year")
  );
  const [displayType, setDisplayType] = useState<string>(
    TrackStats.spotifyStreams
  );

  const dropdownOptions = [
    "Past 1 Year",
    "Past 1 Month",
    "Past 3 Months",
    "Past 6 Months",
    "All Time",
  ];

  const until = moment();

  const selectHandler = (option: string) => {
    switch (option) {
      case "Past 1 Month":
        setSince(moment().subtract(1, "month"));
        break;
      case "Past 3 Months":
        setSince(moment().subtract(3, "months"));
        break;
      case "Past 6 Months":
        setSince(moment().subtract(6, "months"));
        break;
      case "Past 1 Year":
        setSince(moment().subtract(1, "year"));
        break;
      case "All Time":
        setSince(undefined);
        break;
      default:
        break;
    }
  };

  const displayHandler = (type: string) => {
    setDisplayType(type);
  };

  const renderChart = (type: string) => {
    switch (type) {
      case TrackStats.spotifyStreams:
        return (
          <TrackStatGraph
            title="Spotify Streams"
            stat={TrackStats.spotifyStreams}
            chartmetricId={chartmetricId}
            since={since}
            until={until}
            displayStat={trackBase.analysis?.statistics?.spotifyStreams?.toLocaleString()}
          />
        );
      case TrackStats.youtubeViews:
        return (
          <TrackStatGraph
            title="YouTube Views"
            stat={TrackStats.youtubeViews}
            chartmetricId={chartmetricId}
            since={since}
            until={until}
            displayStat={
              trackBase.analysis?.statistics?.youtubeViews &&
              trackBase.analysis?.statistics?.youtubeViews.toLocaleString()
            }
          />
        );
      case TrackStats.shazamCounts:
        return (
          <TrackStatGraph
            title="Shazam Counts"
            stat={TrackStats.shazamCounts}
            chartmetricId={chartmetricId}
            since={since}
            until={until}
            displayStat={
              trackBase.analysis?.statistics?.shazamCounts &&
              trackBase.analysis?.statistics?.shazamCounts.toLocaleString()
            }
          />
        );
      case TrackStats.tiktokVideos:
        return (
          <TrackStatGraph
            title="TikTok Videos"
            stat={TrackStats.tiktokVideos}
            chartmetricId={chartmetricId}
            since={since}
            until={until}
            displayStat={
              trackBase.analysis?.statistics?.tikTokVideosCount &&
              trackBase.analysis?.statistics?.tikTokVideosCount.toLocaleString()
            }
          />
        );
      default:
        return false;
    }
  };

  return (
    <Card css={styles.panel}>
      <h3 css={styles.sectionHeader}>Performance</h3>
      <DropdownSelector
        data-heap-id="song-performance-duration"
        dropdownOptions={dropdownOptions}
        selectHandler={selectHandler}
      />
      <div css={styles.cardGroup}>
        {trackBase.analysis?.statistics?.spotifyStreams && (
          <ClickableCard
            data-heap-id="song-spotify-streams"
            title="Spotify Streams"
            type={TrackStats.spotifyStreams}
            handler={displayHandler}
            displayStat={
              trackBase.analysis?.statistics?.spotifyStreams &&
              abbreviateNumber(
                parseInt(trackBase.analysis?.statistics?.spotifyStreams, 10),
                2
              )
            }
            className={
              displayType === TrackStats.spotifyStreams ? "initialFocused" : ""
            }
          />
        )}
        {trackBase.analysis?.statistics?.youtubeViews && (
          <ClickableCard
            data-heap-id="song-youtube-views"
            title="YouTube Views"
            type={TrackStats.youtubeViews}
            handler={displayHandler}
            displayStat={
              trackBase.analysis?.statistics?.youtubeViews &&
              abbreviateNumber(
                parseInt(trackBase.analysis?.statistics?.youtubeViews, 10),
                2
              )
            }
            className={
              displayType === TrackStats.youtubeViews ? "initialFocused" : ""
            }
          />
        )}
        {trackBase.analysis?.statistics?.shazamCounts && (
          <ClickableCard
            data-heap-id="song-shazam-count"
            title="Shazam Counts"
            type={TrackStats.shazamCounts}
            handler={displayHandler}
            displayStat={
              trackBase.analysis?.statistics?.shazamCounts &&
              abbreviateNumber(trackBase.analysis?.statistics?.shazamCounts, 2)
            }
            className={
              displayType === TrackStats.shazamCounts ? "initialFocused" : ""
            }
          />
        )}
        {trackBase.analysis?.statistics?.tikTokVideosCount && (
          <ClickableCard
            data-heap-id="song-tiktok-videos"
            title="TikTok Videos"
            type={TrackStats.tiktokVideos}
            handler={displayHandler}
            displayStat={
              trackBase.analysis?.statistics?.tikTokVideosCount &&
              abbreviateNumber(
                trackBase.analysis?.statistics?.tikTokVideosCount,
                2
              )
            }
            className={
              displayType === TrackStats.tiktokVideos ? "initialFocused" : ""
            }
          />
        )}
      </div>
      {renderChart(displayType)}
    </Card>
  );
};

export default TrackSocialPanel;
