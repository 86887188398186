import React, { ReactElement } from "react";
import { Layout } from "antd";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "./styles/app.less";
import history from "src/utils/history";
import SearchHome from "src/components/pages/search-home";
import ArtistLandingPage from "src/components/pages/artist-landing-page/container";
import TrackLandingPage from "src/components/pages/track-landing-page/container";

import SearchResults from "src/components/pages/search-results/container";
import appStyle from "./styles/app";

function App(): ReactElement {
  const { Content } = Layout;

  return (
    <div>
      <Router history={history}>
        <Layout css={appStyle.layout}>
          <Content>
            <Switch>
              <Route exact path="/index.html">
                <Redirect to="/" />
              </Route>
              <Route exact path="/">
                <SearchHome />
              </Route>
              <Route
                exact
                path={
                  // The `sa` stands for spotify artist. That indicates that the ID that follows is a spotify artist ID
                  // In the future if/when we switch to a different / internal ID format we can use another anchor code
                  // and then we'll know that we should do a redirect for these ones
                  ["/sa/:artistId", "/:seoText/sa/:artistId"]
                }
              >
                <ArtistLandingPage />
              </Route>
              <Route
                exact
                path={
                  // The `st` stands for spotify track. That indicates that the ID that follows is a spotify track ID
                  // In the future if/when we switch to a different / internal ID format we can use another anchor code
                  // and then we'll know that we should do a redirect for these ones
                  ["/st/:trackId", "/:seoText/st/:trackId"]
                }
              >
                <TrackLandingPage />
              </Route>
              <Route exact path={["/search"]}>
                <SearchResults />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
