import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  heading: css`
    .duration {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
  `,
  wrapper: css`
    margin-left: 3.75rem;
    margin-right: 3.75rem;
    padding-bottom: 5rem;
    .ant-card-body {
      padding: 2.5rem 3.125rem !important;
    }
    h1 {
      font-family: proxima-nova, sans-serif;
      margin-bottom: 1.25rem;
      font-size: 1.5rem;
      font-weight: 600;
    }

    .initialFocused {
      border: 2px solid ${colors.blue.dark} !important;
      color: ${colors.gray.veryDark};
      font-weight: 600;

      .stat {
        color: ${colors.blue.dark} !important;
      }
    }
  `,
};
