import React, { ReactElement } from "react";
import style from "./styles";

const LoadingSpinner = (): ReactElement => {
  return (
    <div css={style.loadingContainer}>
      <div css={style.loading} />
    </div>
  );
};

export default LoadingSpinner;
