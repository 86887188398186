export enum ChartPlatform {
  spotify = "spotify",
  youtube = "youtube",
  tiktok = "tiktok",
}

export enum ChartCountry {
  GLOBAL = "GLOBAL",
  US = "US",
  JP = "JP",
  DE = "DE",
  FR = "FR",
  GB = "GB",
  IT = "IT",
  CN = "CN",
  BR = "BR",
  CA = "CA",
  ES = "ES",
  IN = "IN",
  MX = "MX",
  NL = "NL",
  AU = "AU",
  AR = "AR",
  KR = "KR",
  RU = "RU",
  CH = "CH",
  TW = "TW",
  BE = "BE",
  SE = "SE",
  AT = "AT",
  TR = "TR",
  DK = "DK",
  HK = "HK",
  PL = "PL",
  NO = "NO",
  SA = "SA",
  FI = "FI",
  GR = "GR",
  ZA = "ZA",
  TH = "TH",
  PT = "PT",
  CO = "CO",
  IL = "IL",
  VE = "VE",
  SG = "SG",
  ID = "ID",
  EG = "EG",
  IE = "IE",
  CL = "CL",
  MY = "MY",
  NG = "NG",
  PH = "PH",
  PE = "PE",
  CZ = "CZ",
  NZ = "NZ",
  HU = "HU",
  UA = "UA",
  RO = "RO",
  AE = "AE",
  KZ = "KZ",
  VN = "VN",
  EC = "EC",
  SI = "SI",
  GT = "GT",
  LB = "LB",
  LU = "LU",
  DO = "DO",
  LK = "LK",
  BG = "BG",
  SV = "SV",
  LT = "LT",
  CR = "CR",
  CY = "CY",
  KE = "KE",
  PA = "PA",
  BO = "BO",
  PY = "PY",
  JO = "JO",
  LV = "LV",
  ZW = "ZW",
  HN = "HN",
  EE = "EE",
  KH = "KH",
  PG = "PG",
  NI = "NI",
  MD = "MD",
  LA = "LA",
  SZ = "SZ",
  MN = "MN",
}
