import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  headerContainer: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    padding-left: 1.5rem;
    .sticky-tabs {
      z-index: 4;
      &.is-sticky {
        position: fixed;
        top: 4.0625rem;
      }
    }
  `,
  artists: css`
    display: inline;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: ${colors.gray.dark};
    &:hover {
      color: #0552b5;
    }
  `,
  infoContainer: css`
    display: flex;
    margin-top: 3.75rem;
  `,
  textContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    h1 {
      margin-bottom: 0.625rem !important;
    }
  `,
  name: css`
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.375rem;
  `,
  image: css`
    width: 8.75rem;
    border-radius: 0.25rem;
    margin: 3.75rem 1.875rem 3.75rem 1.875rem;
  `,
  tabs: css`
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${colors.gray.dark};
    margin: 2rem;

    &:focus {
      font-weight: 600;
      color: ${colors.black};
      border-bottom: 0.188rem solid ${colors.black};
    }
  `,
  tabContainer: css`
    margin-bottom: 4.5rem;
  `,
  focus: css`
    font-weight: 600;
    color: ${colors.black};
    border-bottom: 0.188rem solid #0552b5;
  `,
};
