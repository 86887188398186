const tracks = [
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
  {
    title: "STAY (with Justin Bieber)",
    artist: "The Kid LAROI, Justin Bieber",
    imageThumbnail:
      "https://i.scdn.co/image/ab67616d000048518e6551a2944764bc8e33a960",
    trackId: "5PjdY0CKGZdEuoNab3yDmX",
    releaseDate: "2021-07-23T00:00:00.000Z",
    popularityMetricValue: 5286467,
    lyrics:
      "We have it Sunny in the summertime... you have it sunny in the summertime... We have it Sunny in the summertime...",
    theme: "Good Times, Fun, Sentiment",
    genre: "Pop Rock, Electronic",
  },
];
export default tracks;
