import axios from "axios";

import config from "src/config";
import { SearchResults } from "./types/search-results";
import { ArtistBaseMetadata } from "./types/artist-base-metadata";
import { ArtistLicensingHistory } from "./types/artist-licensing-history";
import { ArtistAudienceBreakdown } from "./types/artist-audience-breakdown";
import { ArtistStatTypes } from "./types/artist-stats";
import { StatsResult } from "./types/stats-result";
import { TrackLicensingHistory } from "./types/track-licensing-history";
import { TrackBaseMetadata } from "./types/track-base-metadata";
import { TrackStats } from "./types/track-stats";
import { TrackLyrics } from "./types/track-lyrics";
import { TrackCreditsDetails } from "./types/track-credits";
import { ChartPlatform, ChartCountry } from "./types/charts";
import { TopTrack } from "./types/top-tracks";
import { ArtistLicensingEstimation } from "./types/artist-licensing-estimation";

const Api = axios.create({
  baseURL: config.apiBaseUrl,
  timeout: 60000,
});

function appendStatParams(
  baseUri: string,
  since?: moment.Moment,
  until?: moment.Moment
): string {
  let result = baseUri;
  const args = [];

  if (since) {
    args.push(`since=${since.format("YYYY-MM-DD")}`);
  }
  if (until) {
    args.push(`until=${until.format("YYYY-MM-DD")}`);
  }

  if (args.length > 0) {
    result += `?${args.join("&")}`;
  }

  return result;
}

export async function search(query: string): Promise<SearchResults> {
  if (!query) {
    return { tracks: [], artists: [] };
  }

  const response = await Api.get<SearchResults>(`/search?q=${query}`);
  return response.data;
}

export async function getArtistBase(
  spotifyId: string
): Promise<ArtistBaseMetadata> {
  const response = await Api.get<ArtistBaseMetadata>(`/artist/${spotifyId}`);
  return response.data;
}

export async function getArtistLicensingHistory(
  spotifyId: string
): Promise<ArtistLicensingHistory> {
  const response = await Api.get<ArtistLicensingHistory>(
    `/artist/${spotifyId}/licensing`
  );
  return response.data;
}

export async function getArtistAudienceDetails(
  chartmetricId: number
): Promise<ArtistAudienceBreakdown> {
  const response = await Api.get<ArtistAudienceBreakdown>(
    `/artist/${chartmetricId}/audience-breakdown`
  );
  return response.data;
}

export async function getArtistStats(
  chartmetricId: number,
  stat: ArtistStatTypes,
  since?: moment.Moment,
  until?: moment.Moment
): Promise<StatsResult> {
  const baseUri = `/artist/${chartmetricId}/stats/${stat}`;
  const uri = appendStatParams(baseUri, since, until);
  const response = await Api.get<StatsResult>(uri);
  return response.data;
}

export async function getArtistLicenseEstimationDetails(
  chartmetricId: number
): Promise<ArtistLicensingEstimation> {
  const response = await Api.get<ArtistLicensingEstimation>(
    `/artist/${chartmetricId}/license-estimation`
  );
  return response.data;
}

export async function getTrackBase(
  spotifyId: string
): Promise<TrackBaseMetadata> {
  const response = await Api.get<TrackBaseMetadata>(`/track/${spotifyId}`);
  return response.data;
}

export async function getTrackLicensingHistory(
  spotifyId: string
): Promise<TrackLicensingHistory> {
  const response = await Api.get<TrackLicensingHistory>(
    `/track/${spotifyId}/licensing`
  );
  return response.data;
}

export async function getTrackLyrics(isrc: string): Promise<TrackLyrics> {
  const response = await Api.get<TrackLyrics>(`/track/${isrc}/lyrics`);
  return response.data;
}

export async function getTrackCredits(
  isrc: string
): Promise<TrackCreditsDetails> {
  const response = await Api.get<TrackCreditsDetails>(`/track/${isrc}/credits`);
  return response.data;
}

export async function getTrackStats(
  chartmetricId: number,
  stat: TrackStats,
  since?: moment.Moment,
  until?: moment.Moment
): Promise<StatsResult> {
  const baseUri = `/track/${chartmetricId}/stats/${stat}`;
  const uri = appendStatParams(baseUri, since, until);
  const response = await Api.get<StatsResult>(uri);
  return response.data;
}

export async function getTopTracks(
  platform: ChartPlatform,
  country: ChartCountry
): Promise<TopTrack[]> {
  const response = await Api.get<TopTrack[]>(
    `/top-tracks/${platform}/${country}`
  );
  return response.data;
}
