import React, {
  Fragment,
  ReactElement,
  useState,
  useRef,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { Input, Space, Select, Avatar, Typography } from "antd";
import { artistLink, trackLink } from "src/utils/links";
import { search } from "src/api";
import { ISearchOption } from "src/interfaces/search";
import { searchIcon } from "src/assets";
import useDebounce from "src/utils/useDebounce";
import SearchMenu from "./SearchMenu";

import sectionStyles from "./styles";
import AutoSuggest from "../auto-suggest";

const { Text } = Typography;

export default function SpotifySearchBox(): ReactElement {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLyricSearch, setIsLyricSearch] = useState<boolean>(false);
  const [blockMenu, setBlockMenu] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showAdvancedMenu, setShowAdvancedMenu] = useState<boolean>(false);
  const searchMenuRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [songResults, setSongResults] = useState<ISearchOption[]>([]);
  const [artistResults, setArtistResults] = useState<ISearchOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchQuery, 200);

  const handleSearch = () => {
    if (isLyricSearch) {
      let exactWordQuery = "";

      if (searchQuery.length > 0 && searchQuery !== "") {
        exactWordQuery += `exact=${searchQuery}`;
      }

      history.push({
        pathname: "/search",
        search: `?${exactWordQuery}`,
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    search(debouncedSearchTerm)
      .then((result) => {
        const trackResponse = result.tracks.slice(0, 5).map((track) => {
          return {
            label: (
              <Space size={16} css={sectionStyles.search.result.track}>
                <Avatar src={track.imageThumbnail} size={50} shape="square" />
                <div css={sectionStyles.search.result.titleAndArtist}>
                  <Text className="title">{track.title}</Text>
                  <Text className="artist">{track.artist}</Text>
                </div>
              </Space>
            ),
            key: track.trackId,
            showLabel: `${track.title} by ${track.artist}`,
            value: trackLink(track),
          };
        });

        const artistResponse = result.artists.slice(0, 5).map((a) => {
          return {
            label: (
              <Space size={16} css={sectionStyles.search.result.track}>
                <Avatar src={a.thumbnailUrl} size={50} />
                <div css={sectionStyles.search.result.titleAndArtist}>
                  <Text strong>{a.name}</Text>
                </div>
              </Space>
            ),
            key: a.id,
            showLabel: a.name,
            value: artistLink(a),
          };
        });

        setArtistResults([...artistResponse]);
        setSongResults([...trackResponse]);
        setIsLoading(false);
      })
      .catch((err: Error) => {
        setIsLoading(false);
        throw err;
      });
  }, [debouncedSearchTerm]);

  const handleMenuOnBlur = (event: MouseEvent) => {
    if (
      searchMenuRef.current &&
      !searchMenuRef.current.contains(event.target as HTMLDivElement)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleMenuOnBlur);
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleMenuOnBlur);
    };
  }, []);

  const menu = () => (
    <div css={sectionStyles.search.dropdown}>
      <div
        onClick={() => setIsLyricSearch(false)}
        css={sectionStyles.search.dropdownItem(!isLyricSearch)}
        onKeyDown={() => setIsLyricSearch(false)}
        tabIndex={0}
        role="button"
      >
        Song or Artist
      </div>
      <div
        onClick={() => setIsLyricSearch(true)}
        css={sectionStyles.search.dropdownItem(isLyricSearch)}
        onKeyDown={() => setIsLyricSearch(true)}
        tabIndex={0}
        role="button"
      >
        Lyric
      </div>
    </div>
  );

  return (
    <Fragment>
      <section css={sectionStyles.wrapper} className="___sb">
        <Space css={sectionStyles.search.section} size={25}>
          <div css={sectionStyles.search.border}>
            <Input
              allowClear
              size="large"
              type="text"
              css={sectionStyles.search.input("regular")}
              placeholder={
                !isLyricSearch
                  ? "Enter any song title or artist name"
                  : "Search by Lyrics"
              }
              onFocus={() => {
                if (!blockMenu) setShowMenu(true);
              }}
              onChange={(event) => setSearchQuery(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
              suffix={
                <img
                  src={searchIcon}
                  alt="search icon"
                  css={sectionStyles.search.searchIcon}
                  onClick={() => {
                    handleSearch();
                  }}
                  role="presentation"
                />
              }
              prefix={
                <>
                  <Select
                    value={!isLyricSearch ? "Song or Artist" : "Lyric"}
                    css={sectionStyles.search.select}
                    onMouseEnter={() => setBlockMenu(true)}
                    onMouseLeave={() => setBlockMenu(false)}
                    dropdownRender={menu}
                  />
                  <span css={sectionStyles.search.divider} />
                </>
              }
            />
          </div>
        </Space>
      </section>
      {isLyricSearch && showMenu ? (
        <SearchMenu
          searchQuery={searchQuery}
          setShowAdvancedMenu={setShowAdvancedMenu}
          showAdvancedMenu={showAdvancedMenu}
          searchMenuRef={searchMenuRef}
        />
      ) : null}
      {!isLyricSearch ? (
        <AutoSuggest
          songResults={songResults}
          artistResults={artistResults}
          isLoading={isLoading}
          searchTerm={debouncedSearchTerm}
        />
      ) : null}
    </Fragment>
  );
}
