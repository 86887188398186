import React, { ReactElement } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Image } from "antd";
import { format } from "date-fns";
import { trackLink } from "src/utils/links";
import { SearchTrack } from "src/api/types/search-results";
import LoadingSpinner from "src/components/loading-spinner";
import styles from "./styles";

interface IProps {
  tracks?: SearchTrack[];
}

const formatChartData = (tracks?: SearchTrack[]) => {
  if (!tracks) {
    return [];
  }
  return tracks.map((track, index) => {
    return {
      rank: index + 1,
      title: (
        <Link to={trackLink(track)}>
          <Image
            css={styles.thumbnail}
            preview={false}
            src={track.imageThumbnail}
          />
          <div css={styles.title}>{track.title}</div>
        </Link>
      ),
      artist: track.artist,
      releaseDate: track.releaseDate
        ? format(new Date(track.releaseDate), "MMM dd, yyy")
        : "",
      popularityMetricValue: track.popularityMetricValue?.toLocaleString(),
    };
  });
};

export default function ArtistTopTracksTable({ tracks }: IProps): ReactElement {
  const history = useHistory();

  if (!tracks || tracks.length < 1) {
    return (
      <div css={styles.loading}>
        <LoadingSpinner />
      </div>
    );
  }

  const dataSource = formatChartData(tracks);

  const columns = [
    {
      title: "",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Song",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Artist",
      dataIndex: "artist",
      key: "artist",
    },
  ];

  return (
    <div>
      <Table
        data-heap-id="artist-top-tracks-table"
        rowKey="rank"
        onRow={(record) => {
          return {
            onClick: () => {
              history.push(record.title.props.to);
            },
          };
        }}
        locale={{
          emptyText: "Sorry, we couldn't find the top songs for this artist.",
        }}
        css={styles.resultsTable}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
}
