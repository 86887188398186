import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  nav: css`
    position: fixed;
    font-family: proxima-nova, sans-serif;
    font-size: 0.875rem;
    line-height: 1.125rem;
    top: 0;
    width: 100%;
    background: white;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.15);
    z-index: 2;
    padding: 1.5rem 0 1.375rem 3.75rem;
    a {
      color: ${colors.gray.dark};
    }
    &.is-sticky {
      height: 6.875rem;
    }
  `,
};
