import React from "react";
import ReactDOM from "react-dom";
import ReactHeap from "reactjs-heap";
import config from "src/config";
import withInternationalization from "./i18n";
import App from "./app";
import "./index.css";

ReactHeap.initialize(config.analytics.heapAppID);

const WrappedApp = withInternationalization(App);

ReactDOM.render(
  <React.StrictMode>
    <WrappedApp />
  </React.StrictMode>,
  document.getElementById("root")
);
