import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  banner: css`
    position: absolute;
    left: 0;
    right: 0;
    background: ${colors.gray.veryDark};
    height: 4.5rem;
    color: ${colors.white};
    font-family: Bebas, sans-serif;
    padding: 18px 1rem 1rem 1rem;
    vertical-align: middle;
    word-spacing: 4px;
  `,
  header: css`
    min-height: calc(100vh - 250px);
    h1 {
      text-align: center;
      margin-top: 20rem;
      font-family: Bebas, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 44px;
      line-height: 45px;
    }
    p {
      text-align: center;
      font-family: proxima-nova, sans-serif;
      font-size: 16px;
      line-height: 24px;
    }
    span {
      color: #1f48e7;
    }
  `,
  betaTag: css`
    font-family: proxima-nova, sans-serif;
    background: #414759;
    border-radius: 0.25rem;
    width: 3.125rem;
    text-align: center;
    font-size: 0.5625rem;
    margin-left: 1.0625rem;
    padding: 0.125rem;
  `,
};
