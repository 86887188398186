import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  wrapper: css`
    font-family: proxima-nova, sans-serif;
    margin-left: 3.75rem;
    margin-right: 3.75rem;
    margin-top: 6.0625rem;
    .search-term {
      font-weight: 600;
    }
    .seperator {
      margin-right: 0.625rem;
      margin-left: 0.625rem;
      color: #dfe6ef;
    }
    hr {
      border: 1px solid #dfe6ef;
      margin-top: 1.4375rem;
      margin-bottom: 2.5rem;
      margin-left: -3.75rem;
      margin-right: -3.75rem;
    }
    p {
      margin-bottom: 2.5rem;
    }
  `,
  thumbnail: css`
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 0.25rem;
  `,
  resultsTable: css`
    font-size: 0.875rem;
    margin-bottom: 4rem;
    table {
      font-family: proxima-nova, sans-serif;
      border-collapse: separate;
      border-spacing: 0 0.625rem;
      background: ${colors.gray.f7};
      thead {
        th {
          background: ${colors.gray.f7};
          border: none;
          font-size: 1rem;
          font-weight: 600;
          &:nth-of-type(1) {
            width: 1.5rem;
          }
          &:nth-of-type(2) {
            width: 2.5rem;
          }
          &:nth-of-type(4) {
            width: 18.75rem;
          }
          &:nth-of-type(5) {
            width: 10rem;
          }
        }
        th::before {
          background-color: transparent !important;
        }
      }
      tbody {
        td {
          color: ${colors.black};
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          background: ${colors.white};
          a {
            color: ${colors.black};
          }
        }
        tr:hover > td {
          background: ${colors.gray.tableHeader} !important;
          cursor: pointer;
          mark {
            background: ${colors.gray.tableHeader} !important;
          }
        }
        tr td:first-child,
        tr td:last-child {
          border-radius: 0.25rem !important;
        }
      }
    }
  `,
  artistName: css`
    color: #334e68;
    display: block;
  `,
  lyricColumn: css`
    color: ${colors.blue.gray};
    mark {
      color: ${colors.black};
      background: ${colors.white};
    }
  `,
  searchInput: css`
    height: 3.375rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 0.0625rem solid #dfe6ef;
    box-sizing: border-box;
    border-radius: 0.25rem;
  `,
};
