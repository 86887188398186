import React, { ReactElement } from "react";
import { Card } from "antd";
import { TrackBaseMetadata } from "src/api/types/track-base-metadata";
import styles from "../track-social-panel/styles";
import playlistStyles from "./styles";

interface IProps {
  trackBase: TrackBaseMetadata;
}

const TrackPlaylists = ({ trackBase }: IProps): ReactElement => {
  if (
    !trackBase.analysis?.statistics?.spotifyEditorialPlaylistsCount &&
    !trackBase.analysis?.statistics?.amazonEditorialPlaylistsCount &&
    !trackBase.analysis?.statistics?.appleMusicEditorialPlaylistsCount &&
    !trackBase.analysis?.statistics?.youTubeEditorialPlaylistsCount
  ) {
    return (
      <Card css={styles.panel}>
        <h3 css={styles.sectionHeader}>Playlists</h3>
        <div>
          <p>This song is not on any editorial playlists.</p>
        </div>
      </Card>
    );
  }

  return (
    <Card css={styles.panel}>
      <h3 css={styles.sectionHeader}>Playlists</h3>
      <div css={styles.cardGroup}>
        {trackBase.analysis?.statistics?.spotifyEditorialPlaylistsCount && (
          <Card css={playlistStyles.card}>
            <div css={playlistStyles.info}>
              <p css={playlistStyles.title}>Spotify Editorial Playlists</p>
              <p css={playlistStyles.stat}>
                {trackBase.analysis?.statistics?.spotifyEditorialPlaylistsCount}
              </p>
            </div>
          </Card>
        )}
        {trackBase.analysis?.statistics?.amazonEditorialPlaylistsCount && (
          <Card css={playlistStyles.card}>
            <div css={playlistStyles.info}>
              <p css={playlistStyles.title}>Amazon Editorial Playlists</p>
              <p css={playlistStyles.stat}>
                {trackBase.analysis?.statistics?.amazonEditorialPlaylistsCount}
              </p>
            </div>
          </Card>
        )}
        {trackBase.analysis?.statistics?.appleMusicEditorialPlaylistsCount && (
          <Card css={playlistStyles.card}>
            <div css={playlistStyles.info}>
              <p css={playlistStyles.title}>Apple Editorial Playlists</p>
              <p css={playlistStyles.stat}>
                {
                  trackBase.analysis?.statistics
                    ?.appleMusicEditorialPlaylistsCount
                }
              </p>
            </div>
          </Card>
        )}
        {trackBase.analysis?.statistics?.youTubeEditorialPlaylistsCount ? (
          <Card css={playlistStyles.card}>
            <div css={playlistStyles.info}>
              <p css={playlistStyles.title}>YouTube Editorial Playlists</p>
              <p css={playlistStyles.stat}>
                {trackBase.analysis?.statistics?.youTubeEditorialPlaylistsCount}
              </p>
            </div>
          </Card>
        ) : (
          <></>
        )}
      </div>
    </Card>
  );
};

export default TrackPlaylists;
