import React, { Fragment, ReactElement } from "react";
import SpotifySearchBox from "src/components/spotify-search-box";
import { Row, Col } from "antd";
import styles from "./styles";

const SearchHeader = (): ReactElement => {
  return (
    <Fragment>
      <Row>
        <Col span={24} css={styles.header}>
          <div css={styles.banner}>
            Music Insights
            <div css={styles.betaTag}>BETA</div>
          </div>
          <h1>
            <span>WE CLEAR & LICENSE MUSIC</span> FOR ALL PROJECTS
          </h1>
          <p>
            We negotiate and license music from major labels, publishers and
            other rightsholders for brands, agencies and creatives.
          </p>
          <SpotifySearchBox />
        </Col>
      </Row>
    </Fragment>
  );
};

export default SearchHeader;
