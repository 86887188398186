import { Card } from "antd";
import React, { ReactElement } from "react";
import { ArtistAudienceBreakdown } from "src/api/types/artist-audience-breakdown";
import BrandAffinityCard from "./brand-affinity-card";
import InterestsCard from "./Interests-card";
import styles from "./styles";

interface IProps {
  audienceDetails: ArtistAudienceBreakdown | null;
}

const BrandAffinityAndInterests = ({
  audienceDetails,
}: IProps): ReactElement => {
  const audienceBrandAffinity =
    audienceDetails?.instagramAudience?.audienceBrandAffinity;

  const audienceInterests =
    audienceDetails?.instagramAudience?.audienceInterests;

  return (
    <Card css={styles.card}>
      <h3>BRAND AFFINITY AND INTERESTS</h3>
      <div css={styles.twoCardLayout}>
        <BrandAffinityCard audienceBrandAffinity={audienceBrandAffinity} />
        <InterestsCard audienceInterests={audienceInterests} />
      </div>
    </Card>
  );
};

export default BrandAffinityAndInterests;
