import React, { ReactElement, useState } from "react";
import { Card, Switch, Progress, Table } from "antd";
import DropdownSelector from "src/components/dropdown-selector";
import {
  ArtistAudienceBreakdown,
  AudienceDetails,
} from "src/api/types/artist-audience-breakdown";

import { Column } from "@ant-design/charts";
import colors from "src/colors";
import AudienceBreakdownPieChart from "../audience-breakdown-pie-chart";
import styles from "../styles";

interface IProps {
  audienceDetails: ArtistAudienceBreakdown;
}

const BasicDemographics = ({ audienceDetails }: IProps): ReactElement => {
  const [audience, setAudience] = useState<AudienceDetails | undefined>(
    audienceDetails.instagramAudience
  );
  const [showEthnicity, setShowEthnicity] = useState<boolean>(true);
  const channelDropdownOptions = ["Instagram", "TikTok", "YouTube"];

  const channelDropdownHandler = (option: string) => {
    switch (option) {
      case "Instagram":
        setAudience(audienceDetails.instagramAudience);
        break;
      case "TikTok":
        setAudience(audienceDetails.tikTokAudience);
        break;
      case "YouTube":
        setAudience(audienceDetails.youTubeAudience);
        break;
      default:
        setAudience(audienceDetails.instagramAudience);
        break;
    }
  };

  let ageBreakdown = null;
  if (audience?.audienceGendersPerAge) {
    const data: { code: string; value: number }[] = [];
    audience?.audienceGendersPerAge.forEach((d) => {
      data.push({
        code: d.code,
        value: parseFloat(d.female) + parseFloat(d.male),
      });
    });
    const graphConfig = {
      data,
      isGroup: false,
      xField: "code",
      yField: "value",
      height: 320,
      columnStyle: {
        fill: colors.blue.light,
        radius: 4,
      },
      style: {
        height: 250,
      },
    };
    ageBreakdown = (
      <Card>
        <h4>AGE</h4>
        <Column {...graphConfig} />
      </Card>
    );
  }

  let genderByAgeBreakdown = null;
  if (audience?.audienceGendersPerAge) {
    const data: { code: string; value: number; type: string }[] = [];
    audience?.audienceGendersPerAge.forEach((d) => {
      data.push({ code: d.code, value: parseFloat(d.female), type: "female" });
      data.push({ code: d.code, value: parseFloat(d.male), type: "male" });
    });
    const graphConfig = {
      data,
      isGroup: true,
      xField: "code",
      yField: "value",
      seriesField: "type",
      colorField: "type",
      color: [colors.blue.light, colors.purple.dark],
      columnStyle: {
        radius: 4,
      },
    };
    genderByAgeBreakdown = (
      <Card css={styles.card}>
        <h4>GENDER AND AGE</h4>
        <Column {...graphConfig} />
      </Card>
    );
  }

  let ethnicityBreakdown = null;
  let data;
  if (audience?.audienceEthnicities) {
    data = audience?.audienceEthnicities.map((d) => {
      return {
        name: d.name,
        percent: (
          <Progress
            strokeColor={colors.purple.dark}
            strokeWidth={33}
            percent={Number(d.percent)}
          />
        ),
      };
    });
  }

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: 1,
    },
    {
      title: "",
      dataIndex: "percent",
      key: "percent",
    },
  ];

  ethnicityBreakdown = (
    <Card css={styles.card}>
      <h4>ETHNICITY</h4>
      <Table
        dataSource={data}
        columns={columns}
        showHeader={false}
        pagination={false}
      />
    </Card>
  );

  const handleSwitch = () => {
    setShowEthnicity(!showEthnicity);
  };

  return (
    <Card css={styles.section}>
      <h3>DEMOGRAPHICS</h3>
      <div css={styles.select}>
        <DropdownSelector
          data-heap-id="artist-audience-platform-select"
          dropdownOptions={channelDropdownOptions}
          selectHandler={channelDropdownHandler}
        />
        <p>View Details</p>
        <Switch
          data-heap-id="artist-audience-ethnicity-toggle"
          css={styles.switch}
          checked={showEthnicity}
          onChange={handleSwitch}
        />
      </div>
      <div css={styles.twoCardLayout}>
        <AudienceBreakdownPieChart
          angleField="percent"
          colorField="code"
          addPercentSign
          data={audience?.audienceGenders}
          title="GENDER"
        />
        <div css={styles.smallCard}>{ageBreakdown}</div>
      </div>
      {genderByAgeBreakdown}
      {showEthnicity && (
        <div css={styles.twoCardLayout}>
          <div css={styles.smallBarChartCard}>{ethnicityBreakdown}</div>
        </div>
      )}
    </Card>
  );
};

export default BasicDemographics;
