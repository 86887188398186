import React, { ReactElement } from "react";
import { Modal } from "antd";
import { TrackLyrics } from "src/api/types/track-lyrics";
import Paragraph from "antd/lib/typography/Paragraph";
import styles from "./styles";

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  lyrics?: TrackLyrics;
  title: string;
}

const LyricsModal = ({
  isOpen,
  setIsOpen,
  lyrics,
  title,
}: IProps): ReactElement => {
  const handleOk = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      css={styles.lyricsModal}
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div css={styles.flexbox}>
        <h3 css={styles.sectionHeader}>Lyrics</h3>
        <h4 css={styles.songTitle}>{title}</h4>
        <Paragraph css={styles.lyrics}>{lyrics?.lyrics}</Paragraph>
        <Paragraph>{lyrics?.copyright}</Paragraph>
        <Paragraph>Writer: {lyrics?.writer}</Paragraph>
      </div>
    </Modal>
  );
};

export default LyricsModal;
