import React, { ReactElement, useState } from "react";
import { Divider, Button, Input } from "antd";
import { useHistory } from "react-router-dom";

import sectionStyles from "../styles";

interface IProps {
  searchMenuRef: React.Ref<HTMLDivElement>;
  searchQuery: string;
  showAdvancedMenu: boolean;
  setShowAdvancedMenu: (val: boolean) => void;
}
export default function SearchMenu({
  searchMenuRef,
  searchQuery,
  showAdvancedMenu,
  setShowAdvancedMenu,
}: IProps): ReactElement {
  const SimpleMenu = () => {
    return (
      <div css={sectionStyles.menuWrapper}>
        <div ref={searchMenuRef} css={sectionStyles.menu}>
          <p css={sectionStyles.popupText}>Exact Word or Phrase</p>
          <div css={sectionStyles.searchBox}>Search for {searchQuery}</div>
          <Divider />
          <Button
            onClick={() => setShowAdvancedMenu(true)}
            css={sectionStyles.advanceBtn}
          >
            Advanced Lyric Search
          </Button>
        </div>
      </div>
    );
  };

  const AdvancedMenu = () => {
    const [exactWord, setExactWord] = useState<string>("");
    const [includesWords, setIncludesWords] = useState<string>("");
    const [excludeWords, setExcludeWords] = useState<string>("");
    const includedWords = includesWords.split(/,|, /);
    const excludedWords = excludeWords.split(/,|, /);
    let includedWordsQuery = "";
    let excludedWordsQuery = "";
    let exactWordQuery = "";
    const history = useHistory();

    const handleAdvancedSearch = () => {
      includedWords.forEach((word) => {
        if (word.length > 0 && word !== "") {
          includedWordsQuery += `&any=${word}`;
        }
      });

      excludedWords.forEach((word) => {
        if (word.length > 0 && word !== "") {
          excludedWordsQuery += `&none=${word}`;
        }
      });

      if (exactWord.length > 0 && exactWord !== "") {
        exactWordQuery += `exact=${exactWord}`;
      }

      history.push({
        pathname: "/search",
        search: `?${exactWordQuery}${includedWordsQuery}${excludedWordsQuery}`,
      });
    };

    return (
      <div css={sectionStyles.menuWrapper}>
        <div ref={searchMenuRef} css={sectionStyles.menu}>
          <p css={sectionStyles.advancedText}>Advanced Lyric Search</p>
          <Divider />
          <p css={sectionStyles.advancedSubText}>
            Fields are optional. Use as few or as many as you like.
          </p>
          <div css={sectionStyles.inputRow}>
            <p style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
              This exact word or phrase
            </p>
            <Input
              placeholder="e.g. Summer Fun"
              css={sectionStyles.searchInput}
              value={exactWord}
              onChange={(event) => setExactWord(event.target.value)}
            />
          </div>
          <div css={sectionStyles.inputRow}>
            <p style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
              Any of these words
            </p>
            <Input
              placeholder="e.g. Summer, Fun, Hot"
              css={sectionStyles.searchInput}
              value={includesWords}
              onChange={(event) => setIncludesWords(event.target.value)}
            />
          </div>
          <div css={sectionStyles.inputRow}>
            <p style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
              None of these words
            </p>
            <Input
              placeholder="e.g. Winter"
              css={sectionStyles.searchInput}
              type="text"
              value={excludeWords}
              onChange={(event) => setExcludeWords(event.target.value)}
            />
          </div>
          <div css={sectionStyles.advancedButtonGroup}>
            <Button
              onClick={() => setShowAdvancedMenu(false)}
              size="large"
              css={sectionStyles.advancedButton(false)}
            >
              Cancel
            </Button>
            <Button
              size="large"
              css={sectionStyles.advancedButton(true)}
              onClick={handleAdvancedSearch}
              type="primary"
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return showAdvancedMenu ? <AdvancedMenu /> : <SimpleMenu />;
}
