import React, { ReactElement, useState } from "react";
import { Card } from "antd";
import moment from "moment";
import { TrackBaseMetadata } from "src/api/types/track-base-metadata";
import { TrackStats } from "src/api/types/track-stats";
import TrackStatGraph from "../track-stat-graph";
import styles from "./styles";
import DropdownSelector from "../dropdown-selector";
import { spotifyPopularityTooltip } from "../artist-spotify-panel";

interface IProps {
  trackBase: TrackBaseMetadata;
  chartmetricId?: number;
}

const TrackSpotifyPanel = ({
  trackBase,
  chartmetricId,
}: IProps): ReactElement => {
  const [since, setSince] = useState<moment.Moment | undefined>(undefined);

  const dropdownOptions = [
    "Past 1 Year",
    "Past 1 Month",
    "Past 3 Months",
    "Past 6 Months",
    "All Time",
  ];

  const until = moment();

  const selectHandler = (option: string) => {
    switch (option) {
      case "Past 1 Month":
        setSince(moment().subtract(1, "month"));
        break;
      case "Past 3 Months":
        setSince(moment().subtract(3, "months"));
        break;
      case "Past 6 Months":
        setSince(moment().subtract(6, "months"));
        break;
      case "Past 1 Year":
        setSince(moment().subtract(1, "year"));
        break;
      case "All Time":
        setSince(undefined);
        break;
      default:
        break;
    }
  };

  return (
    <Card css={styles.panel}>
      <h3 css={styles.sectionHeader}>Spotify Popularity</h3>
      <div css={styles.dropdown}>
        <DropdownSelector
          data-heap-id="song-spotify-duration-select"
          dropdownOptions={dropdownOptions}
          selectHandler={selectHandler}
        />
      </div>
      <TrackStatGraph
        title="Spotify Popularity"
        stat={TrackStats.spotifyPopularity}
        chartmetricId={chartmetricId}
        since={since}
        until={until}
        displayStat={trackBase.spotify.popularity}
        tooltip={spotifyPopularityTooltip}
      />
    </Card>
  );
};

export default TrackSpotifyPanel;
