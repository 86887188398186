import { css } from "@emotion/react";

export default {
  wrapper: css`
    margin-left: 3.75rem;
    margin-right: 3.75rem;
    h2 {
      display: inline-block;
      font-size: 1.25rem;
    }
    &.results-focus {
      filter: blur(20px);
      pointer-events: none;
    }
  `,
  select: css`
    border-radius: 0.25rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    color: #1f48e7;
    font-weight: 600;
    font-size: 1rem;
    min-width: 150px;
    border-color: transparent !important;
    .ant-select-selector {
      background-color: rgba(31, 72, 231, 0.05) !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      height: 50px !important;
      border-radius: 4px !important;
    }
    .ant-select-arrow {
      color: #1f48e7;
    }
  `,
  toolTipIcon: css`
    color: #bcccdc;
    width: 1.25rem !important;
    height: 1.25rem !important;
    cursor: pointer;
  `,
};
