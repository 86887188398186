import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  tableWrapper: css`
    div:first-of-type {
      margin-left: 0 !important;
    }
    flex: 1;
    .ant-table-thead > tr > th {
      background: ${colors.gray.tableHeader} !important;
    }

    .table-row-gray {
      background: ${colors.gray.veryLight};
    }
  `,
};
