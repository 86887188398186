import { css } from "@emotion/react";

export default {
  flexbox: css`
    column-count: 2;
    column-gap: 1.875rem;
  `,
  lyrics: css`
    white-space: pre-line;
  `,
  lyricsModal: css`
    width: 1023px !important;
    .ant-modal-body {
      overflow-x: scroll;
      max-height: 52.5rem;
      padding: 3.125rem !important;
    }
  `,
  sectionHeader: css`
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 1.875rem;
  `,
  songTitle: css`
    font-weight: 600;
  `,
};
