import React, { ReactElement } from "react";

import styles from "./styles";

interface Props {
  trackId: string;
}

export function LargeSpotifyPlayer({ trackId }: Props): ReactElement {
  return (
    <iframe
      css={styles.spotifyEmbed}
      src={`https://open.spotify.com/embed/track/${trackId}`}
      allowTransparency
      allow="encrypted-media"
      title="Spotify Player"
    />
  );
}

export function InlineSpotifyPlayer({ trackId }: Props): ReactElement {
  return (
    <iframe
      data-heap-id="spotify-player"
      css={styles.inlinePlayer}
      src={`https://open.spotify.com/embed/track/${trackId}`}
      allowTransparency
      allow="encrypted-media"
      title="Spotify Player"
    />
  );
}
