import React, { ReactElement, useEffect, useState } from "react";
import { Card } from "antd";
import { Line } from "@ant-design/charts";

import { getTrackStats } from "src/api";
import { Stat } from "src/api/types/stats-result";
import { TrackStats } from "src/api/types/track-stats";
import format from "date-fns/format";
import abbreviateNumber from "src/utils/helpers";
import colors from "src/colors";
import styles from "./styles";
import LoadingSpinner from "../loading-spinner";

interface Props {
  title: string;
  stat: TrackStats;
  chartmetricId?: number;
  since?: moment.Moment;
  until?: moment.Moment;
  displayStat?: number | string;
  tooltip?: ReactElement;
}

interface ChartMetricValue {
  value: number;
  time: string;
}

export default function TrackStatGraph({
  title,
  chartmetricId,
  stat,
  since,
  until,
  displayStat,
  tooltip,
}: Props): ReactElement {
  const [stats, setStats] = useState<Stat[] | null>(null);
  const [dateRange, setDateRange] = useState({
    min: "",
    max: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [displayStatFallback, setDisplayStatFallback] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (!chartmetricId) {
      return;
    }

    setIsLoading(true);
    getTrackStats(chartmetricId, stat, since, until)
      .then((results) => {
        setIsLoading(false);
        if (results.data.length < 1) {
          return;
        }
        if (!displayStat) {
          setDisplayStatFallback(results.data[results.data.length - 1].value);
        }
        setDateRange({
          min: format(new Date(results.data[0].time), "MMM d, yyy"),
          max: format(
            new Date(results.data[results.data.length - 1].time),
            "MMM d, yyy"
          ),
        });
        const formattedResults = results.data.map(
          (result: ChartMetricValue) => {
            return { ...result, time: format(new Date(result.time), "MMM d") };
          }
        );
        setStats(formattedResults);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  }, [chartmetricId, stat, since, until, displayStat]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!stats || stats.length === 0) {
    return <></>;
  }

  let min = Number.MAX_SAFE_INTEGER;
  let max = 0;
  stats.forEach((m) => {
    if (m.value < min) {
      min = m.value;
    }
    if (m.value > max) {
      max = m.value;
    }
  });

  const graphConfig = {
    data: stats,
    xField: "time",
    yField: "value",
    meta: {
      value: {
        min,
        max,
      },
    },
    yAxis: {
      label: {
        formatter: (v: string) => {
          return abbreviateNumber(parseFloat(v), 2);
        },
      },
    },
    color: colors.blue.light,
    lineStyle: {
      lineWidth: 3,
    },
    smooth: true,
  };

  return (
    <React.Fragment>
      <Card>
        <p css={styles.chartTitle}>{title}</p>
        <div css={styles.toolTip}>{tooltip}</div>
        <h2 css={styles.displayStat}>{displayStat || displayStatFallback}</h2>
        <p css={styles.dateRange}>{`${dateRange.min} - ${dateRange.max}`}</p>
        <Line {...graphConfig} />
      </Card>
    </React.Fragment>
  );
}
