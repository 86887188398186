export enum ArtistStats {
  instagramFollowers = "instagramFollowers",
  spotifyFollowers = "spotifyFollowers",
  spotifyListeners = "spotifyListeners",
  spotifyPopularity = "spotifyPopularity",
  tiktokFollowers = "tiktokFollowers",
  tiktokLikes = "tiktokLikes",
  youtubeArtistsViews = "youtubeArtistsViews",
  youtubeSubscribers = "youtubeSubscribers",
  youtubeViews = "youtubeViews",
}

export type ArtistStatTypes = keyof typeof ArtistStats;
