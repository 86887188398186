import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  dropdown: css`
    margin: 1rem;
    width: 3rem;

    .dropdown {
      border-radius: 0.25rem;
      margin-left: -1rem;
      margin-right: 0.75rem;
      color: ${colors.black};
      font-weight: 400;
      font-size: 1rem;
      min-width: 150px;
      border-color: transparent !important;
      .ant-select-selector {
        background-color: ${colors.white};
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        height: 50px !important;
        border-radius: 4px !important;

        &:hover {
          background: ${colors.brandWhite};
          border-color: transparent !important;
        }
      }
    }
    .dropdown > .ant-select-arrow {
      color: ${colors.black};
    }
  `,
};
