import React, { ReactElement } from "react";
import {
  ArtistAudienceBreakdown,
  AudienceDetails,
  SpotifyTopListenerLocations,
} from "src/api/types/artist-audience-breakdown";
import Table, { ColumnsType } from "antd/es/table";
import LoadingSpinner from "../loading-spinner";
import styles from "./styles";
import DropdownSelector from "../dropdown-selector";

interface IProps {
  audienceDetails: ArtistAudienceBreakdown | null;
  selectHandler: (value: string) => void;
  selectedPlatform: string;
}

interface ILocation {
  rank?: number;
  name: string;
  listeners: number;
  countryCode: string;
}

const formatSpotifyData = (audience?: SpotifyTopListenerLocations) => {
  if (!audience) {
    return [];
  }

  const tableData = audience.cities.map((city) => {
    return {
      name: city.name,
      countryCode: city.countryCode,
      listeners: city.listeners,
    };
  });

  return tableData.sort((a, b) => b.listeners - a.listeners);
};

const formatInstagramData = (audience?: AudienceDetails) => {
  if (!audience || !audience.topCities) {
    return [];
  }

  const tableData = audience.topCities.map((city) => {
    return {
      name: city.name,
      countryCode: city.country,
      listeners: city.followers,
    };
  });

  return tableData.sort((a, b) => b.listeners - a.listeners);
};

const formatTableData = (
  selectedPlatform: string,
  audience?: ArtistAudienceBreakdown
) => {
  switch (selectedPlatform) {
    case "Spotify":
      return formatSpotifyData(audience?.spotifyWherePeopleListen);
    case "Instagram":
      return formatInstagramData(audience?.instagramAudience);
    default:
      return [];
  }
};

const ArtistLocationTable = ({
  audienceDetails,
  selectHandler,
  selectedPlatform,
}: IProps): ReactElement => {
  if (!audienceDetails) {
    return <LoadingSpinner />;
  }

  const dataSource = formatTableData(selectedPlatform, audienceDetails);

  const columns: ColumnsType<ILocation> = [
    {
      title: "Rank",
      key: "index",
      render: (text, record: ILocation) => {
        return dataSource.indexOf(record) + 1;
      },
    },
    {
      title: "City",
      dataIndex: "name",
      key: "name",
    },
    {
      title: selectedPlatform === "Instagram" ? "Followers" : "Listeners",
      dataIndex: "listeners",
      key: "listeners",
      align: "right",
      render: (value: number) => value.toLocaleString(),
    },
  ];

  const platformDropdownOptions = ["Spotify", "Instagram"];

  return (
    <div css={styles.tableWrapper}>
      <DropdownSelector
        data-heap-id="artist-location-platform-select"
        dropdownOptions={platformDropdownOptions}
        selectHandler={(value) => selectHandler(value)}
      />
      <Table
        rowClassName={(record, index) => (index % 2 === 0 ? "" : "grayrow")}
        size="small"
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default ArtistLocationTable;
