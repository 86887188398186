import React, { ReactElement, useState } from "react";
import { Card, Progress } from "antd";
import { TrackBaseMetadata } from "src/api/types/track-base-metadata";
import colors from "src/colors";
import { format } from "date-fns";
import { TrackCreditsDetails } from "src/api/types/track-credits";
import { uniq } from "lodash";
import styles from "./styles";
import LyricsDisplay from "../lyrics-display";
import { pitchClassToString } from "../../utils/audio-features";
import LoadingSpinner from "../loading-spinner";

interface IProps {
  trackBase: TrackBaseMetadata;
  ownership?: TrackCreditsDetails;
  ownershipLoading: boolean;
}

export default function SongDetails({
  trackBase,
  ownership,
  ownershipLoading,
}: IProps): ReactElement {
  const isrc = trackBase?.spotify.isrc || trackBase?.analysis?.isrc;
  const [publisher, setPublisher] = useState<string>("");

  const artistArray = ownership?.credits
    .filter((credit) => {
      return credit.role === "Main Artist";
    })
    .map((credit) => credit.name);

  const composerLyricistArray = ownership?.credits
    .filter((credit) => {
      return credit.role === "Composer Lyricist";
    })
    .map((credit) => credit.name);

  const lyricistArray = ownership?.credits
    .filter((credit) => {
      return credit.role === "Lyricist";
    })
    .map((credit) => credit.name);

  const labelArray = ownership?.releases
    .filter((releaseLabel) => {
      return releaseLabel.label;
    })
    .map((releaseLabel) => releaseLabel.label);

  const associatedPerformerArray = ownership?.credits
    .filter((credit) => {
      return credit.role === "Associated Performer";
    })
    .map((credit) => credit.name);

  const engineerArray = ownership?.credits
    .filter((credit) => {
      return credit.roleGroup === "Engineers";
    })
    .map((credit) => credit.name);

  const producerArray = ownership?.credits
    .filter((credit) => {
      return credit.roleGroup === "Producers";
    })
    .map((credit) => credit.name);

  const features =
    trackBase.analysis?.audioFeatures || trackBase.spotify.audioFeatures || {};

  function isValidFeature(feature: number | undefined): feature is number {
    return typeof feature !== "undefined";
  }

  return (
    <div id="details" className="landing-anchor">
      <h1>Song Details</h1>
      <div css={styles.songDetails}>
        <Card css={styles.panel}>
          <h3 css={styles.sectionHeader}>Creative Info</h3>
          <div css={styles.detail}>
            <span>Genres</span>
            <span>{trackBase.analysis?.tags}</span>
          </div>
          <div css={styles.detail}>
            <span>Explicit Content</span>
            <span>{trackBase.spotify?.explicit ? "Yes" : "No"}</span>
          </div>
          {isValidFeature(features?.key) && (
            <div css={styles.detail}>
              <span>Key</span>
              <span>{pitchClassToString(features?.key)}</span>
            </div>
          )}
          {isValidFeature(features?.tempo) && (
            <div css={styles.detail}>
              <span>BPM</span>
              <span>{Math.round(features.tempo)}</span>
            </div>
          )}
          {isValidFeature(features?.energy) && (
            <div css={styles.detail}>
              <span>Energy</span>
              <span>
                <Progress
                  percent={Math.round(features.energy * 100)}
                  strokeColor={colors.blue.light}
                  trailColor="#e8e8e8"
                />
              </span>
            </div>
          )}
          {isValidFeature(features?.danceability) && (
            <div css={styles.detail}>
              <span>Danceability</span>
              <span>
                <Progress
                  percent={Math.round(features.danceability * 100)}
                  strokeColor={colors.blue.light}
                  trailColor="#e8e8e8"
                />
              </span>
            </div>
          )}
          {isValidFeature(features?.acousticness) && (
            <div css={styles.detail}>
              <span>Acousticness</span>
              <span>
                <Progress
                  percent={Math.round(features.acousticness * 100)}
                  strokeColor={colors.blue.light}
                  trailColor="#e8e8e8"
                />
              </span>
            </div>
          )}
          {isValidFeature(features?.liveness) && (
            <div css={styles.detail}>
              <span>Liveness</span>
              <span>
                <Progress
                  percent={Math.round(features.liveness * 100)}
                  strokeColor={colors.blue.light}
                  trailColor="#e8e8e8"
                />
              </span>
            </div>
          )}
          {isValidFeature(features?.valence) && (
            <div css={styles.detail}>
              <span>Valence</span>
              <span>
                <Progress
                  percent={Math.round(features.valence * 100)}
                  strokeColor={colors.blue.light}
                  trailColor="#e8e8e8"
                />
              </span>
            </div>
          )}
          {isValidFeature(features?.instrumentalness) && (
            <div css={styles.detail}>
              <span>Instrumentalness</span>
              <span>
                <Progress
                  percent={Math.round(features.instrumentalness * 100)}
                  strokeColor={colors.blue.light}
                  trailColor="#e8e8e8"
                />
              </span>
            </div>
          )}
        </Card>
        <Card css={styles.panel}>
          <h3 css={styles.sectionHeader}>Lyrics</h3>
          <LyricsDisplay
            isrc={isrc}
            setPublisher={setPublisher}
            artists={artistArray}
            songTitle={trackBase.spotify.title}
          />
        </Card>
      </div>
      <div css={styles.songDetails}>
        <Card css={styles.panel}>
          <h3 css={styles.sectionHeader}>Recording Info</h3>
          <div css={styles.detail}>
            <span>Release Date</span>
            <span>
              {trackBase.analysis?.releaseDate &&
                format(
                  new Date(trackBase.analysis.releaseDate),
                  "LLL dd, yyyy"
                )}
            </span>
          </div>
          <div css={styles.detail}>
            <span>Duration</span>
            <span>
              {trackBase.analysis?.durationMs &&
                format(new Date(trackBase.analysis.durationMs), "m:ss")}
            </span>
          </div>
          <div css={styles.detail}>
            <span>ISRC</span>
            <span>{trackBase.spotify.isrc}</span>
          </div>
          {publisher && (
            <div css={styles.detail}>
              <span>Publisher</span>
              <span>{publisher}</span>
            </div>
          )}
          <h3 css={[styles.sectionHeader, styles.topMargin]}>
            Recording Credits
          </h3>
          {ownershipLoading && <LoadingSpinner />}
          {artistArray && (
            <div css={styles.detail}>
              <span>Main Artists</span>
              <span>{artistArray.join(", ")}</span>
            </div>
          )}
          {labelArray && (
            <div css={styles.detail}>
              <span>Record Labels</span>
              <span>{uniq(labelArray).join(", ")}</span>
            </div>
          )}
          {associatedPerformerArray && associatedPerformerArray.length > 0 && (
            <div css={styles.detail}>
              <span>Associated Performers</span>
              <span>{uniq(associatedPerformerArray).join(", ")}</span>
            </div>
          )}
          {producerArray && producerArray.length > 0 && (
            <div css={styles.detail}>
              <span>Producers</span>
              <span>{uniq(producerArray).join(", ")}</span>
            </div>
          )}
          {engineerArray && engineerArray.length > 0 && (
            <div css={styles.detail}>
              <span>Engineers</span>
              <span>{uniq(engineerArray).join(", ")}</span>
            </div>
          )}
        </Card>
        <Card css={styles.panel}>
          <h3 css={styles.sectionHeader}>Composition Credits</h3>
          <div css={styles.detail}>
            <span>Composers</span>
            <span>{trackBase.analysis?.composerName}</span>
          </div>
          {ownershipLoading && <LoadingSpinner />}
          {composerLyricistArray && composerLyricistArray.length > 0 && (
            <div css={styles.detail}>
              <span>Composer Lyricists</span>
              <span>{uniq(composerLyricistArray).join(", ")}</span>
            </div>
          )}
          {lyricistArray && lyricistArray.length > 0 && (
            <div css={styles.detail}>
              <span>Lyricists</span>
              <span>{uniq(lyricistArray).join(", ")}</span>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}
