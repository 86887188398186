import React, { ReactElement } from "react";
import { Select } from "antd";
import styles from "./styles";

interface IProps {
  dropdownOptions: string[];
  selectHandler: (option: string) => void;
}

const DropdownSelector = ({
  dropdownOptions,
  selectHandler,
}: IProps): ReactElement => {
  const { Option } = Select;

  return (
    <div css={styles.dropdown}>
      <Select
        defaultValue={dropdownOptions[0]}
        onSelect={selectHandler}
        dropdownMatchSelectWidth={false}
        className="dropdown"
      >
        {dropdownOptions.map((option) => {
          return (
            <Option key={option} value={option}>
              {option}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default DropdownSelector;
