import React, { ReactElement, useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { getName } from "country-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { SearchTrack } from "../../api/types/search-results";
import { getTopTracks } from "../../api";
import { ChartCountry, ChartPlatform } from "../../api/types/charts";
import styles from "./styles";
import ResultsTable from "../results-table";

const { Option } = Select;

const getTooltip = (platform: ChartPlatform) => {
  switch (platform) {
    case ChartPlatform.tiktok:
      return "Global tally of the top used tracks on TikTok, updated weekly.";
    case ChartPlatform.spotify:
      return "Global Tally of the top streamed songs on Spotify, updated weekly.";
    case ChartPlatform.youtube:
      return "Global tally of the top songs featured on YouTube, updated weekly.";
    default:
      return "";
  }
};

export default function TopTracksChart(): ReactElement {
  const [topTracks, setTopTracks] = useState<SearchTrack[] | undefined>();
  const [selectedPlatform, setSelectedPlatform] = useState(
    ChartPlatform.spotify
  );
  const [selectedCountry, setSelectedCountry] = useState(ChartCountry.US);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getTopTracks(selectedPlatform, selectedCountry)
      .then((results) => {
        setIsLoading(false);
        setTopTracks(results);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  }, [selectedPlatform, selectedCountry]);

  return (
    <div className="top-tracks-wrapper" css={styles.wrapper}>
      <h2>Top Songs on</h2>
      <Select
        data-heap-id="top-tracks-platform-select"
        css={styles.select}
        bordered={false}
        onChange={(value) => setSelectedPlatform(value)}
        defaultValue={ChartPlatform.spotify}
      >
        <Option value={ChartPlatform.spotify}>Spotify</Option>
        <Option value={ChartPlatform.tiktok}>TikTok</Option>
        <Option value={ChartPlatform.youtube}>YouTube</Option>
      </Select>{" "}
      <h2>in</h2>
      <Select
        showSearch
        data-heap-id="top-tracks-country-select"
        css={styles.select}
        bordered={false}
        onChange={(value) => setSelectedCountry(value)}
        defaultValue={ChartCountry.US}
        filterOption={(input, option) =>
          option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
      >
        {Object.keys(ChartCountry)
          .sort((codeA: string, codeB: string) => {
            const nameA = getName(codeA) || codeA;
            const nameB = getName(codeB) || codeB;
            return nameA?.localeCompare(nameB);
          })
          .map((code) => {
            return (
              <Option key={code} value={code}>
                {getName(code)}
              </Option>
            );
          })}
      </Select>{" "}
      <Tooltip title={getTooltip(selectedPlatform)}>
        <FontAwesomeIcon css={styles.toolTipIcon} icon={faInfoCircle} />
      </Tooltip>
      <ResultsTable
        tracks={topTracks}
        platform={selectedPlatform}
        isLoading={isLoading}
      />
    </div>
  );
}
