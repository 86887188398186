import { css } from "@emotion/react";

export default {
  cardGroup: css`
    display: flex;
  `,
  panel: css`
    margin-bottom: 1.875rem;
    box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
  `,
  sectionHeader: css`
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.0938rem;
    text-transform: uppercase;
    margin-bottom: 1.875rem;
  `,
};
