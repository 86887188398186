import { css } from "@emotion/react";

export default {
  wrapper: css`
    margin-left: 3.75rem;
    margin-right: 3.75rem;
    h2 {
      font-size: 1.25rem;
    }
  `,
};
