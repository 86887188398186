import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  component: css`
    height: 50rem;
    width: 100%;
    text-align: center;

    h1 {
      margin-top: 7.5rem;
      font-weight: 600;
      font-size: 24px;
      font-style: normal;
      line-height: 30px;
    }

    p {
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
    }

    span {
      color: ${colors.blue.a80};
    }

    #footnote {
      color: ${colors.black};
      font-size: 14px;
      line-height: 18px;
    }
  `,
  dropdownSelectors: css`
    display: flex;
    justify-content: center;

    .ant-select-selection-placeholder {
      color: ${colors.black};
    }

    .ant-form-item {
      margin: 0.5rem;

      .ant-form-item-label {
        opacity: 0.8;
      }

      .ant-select-selection-placeholder {
        text-align: left;
        opacity: 0.6;
      }

      .ant-select-selection-item {
        text-align: left;
        color: ${colors.black};
      }
    }
  `,
  button: css`
    margin: 2rem;
    border-radius: 4px;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    width: 152px;
    height: 50px;

    span {
      color: white;
    }

    :disabled,
    :disabled:hover {
      background-color: #1890ff;
      opacity: 0.4;
    }
  `,
  estimate: css`
    margin: 2rem;
    margin-bottom: 1rem;
    font-size: 32px;
    line-height: 38px;
    font-weight: bold;
    width: 40%;
    left: 30%;
    border-radius: 10px;

    span {
      color: ${colors.gray.f60};
    }
  `,
  feedback: css`
    font-weight: normal;
    margin-top: 15px;
    input {
      margin-right: 5px;
    }
    label {
      font-size: 1rem;
      padding-right: 1rem;
    }
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
  `,
  feedbackForm: css`
    display: flex;
    font-size: 1rem;
    font-weight: normal;
    margin: 0 auto;
    justify-content: center;
    p {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 0.125rem;
    }
    input {
      padding: 5px;
    }
  `,
  white: css`
    color: ${colors.white} !important;
  `,
  nameInput: css`
    margin-bottom: 1rem;
  `,
  paddingBottom: css`
    padding-bottom: 5rem;
  `,
};
