import { css } from "@emotion/react";

export default {
  select: css`
    display: flex;
    margin-left: 1rem;
    font-size: 14px;
    width: 100%;

    p {
      margin-top: 1.75rem;
      margin-left: 5rem;
    }
  `,
  switch: css`
    margin-top: 1.75rem;
    margin-left: 1rem;
  `,
  twoCardLayout: css`
    display: flex;
    justify-content: space-between;
  `,
  smallCard: css`
    flex: 1;
    margin: 1rem;
    width: 10rem;
  `,
  smallBarChartCard: css`
    width: 50%;
  `,
  card: css`
    margin: 1rem;

    tbody {
      tr td {
        border: none;
      }
    }
  `,
  section: css`
    font-family: proxima-nova, sans-serif;

    h4 {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }

    .ant-progress-inner {
      border-radius: 4px !important;
    }

    .ant-progress-bg {
      border-radius: 4px !important;
    }
  `,
};
