import React, { ReactElement } from "react";
import SearchHeader from "./search-header";
import TopTracksChart from "../../top-tracks-chart";

export default function SearchHome(): ReactElement {
  return (
    <>
      <SearchHeader />
      <TopTracksChart />
    </>
  );
}
