import React, { ReactElement, ReactNode } from "react";
import { Card, Table } from "antd";
import { TrackLicensingHistory } from "src/api/types/track-licensing-history";
import { format } from "date-fns";
import styles from "./styles";

const formatTableData = (data?: TrackLicensingHistory) => {
  const licenseArray = [];
  const episodes = data?.episodes.map((episode) => {
    return {
      type: "TV Episode",
      airDate: format(new Date(episode.appearance.airDate), "LLLL d yyyy"),
      name: `${episode.showName} ${episode.seasonName} Episode ${episode.episodeNumber}`,
    };
  });

  if (episodes) {
    licenseArray.push(...episodes);
  }

  const movies = data?.movies.map((movie) => {
    return {
      type: "Movie",
      airDate: format(new Date(movie.appearance.airDate), "LLLL d yyyy"),
      name: movie.name,
    };
  });

  if (movies) {
    licenseArray.push(...movies);
  }

  const games = data?.games.map((game) => {
    return {
      type: "Game",
      airDate: format(new Date(game.airDate), "LLLL d yyyy"),
      name: game.name,
    };
  });

  if (games) {
    licenseArray.push(...games);
  }

  licenseArray.sort(
    (a, b) => new Date(b.airDate).getTime() - new Date(a.airDate).getTime()
  );

  return licenseArray;
};

interface Props {
  data?: TrackLicensingHistory;
}

export default function TrackLicensingDisplay({ data }: Props): ReactElement {
  const licenseCount = {
    episodes: data?.shows.length,
    movies: data?.movies.length,
    games: data?.games.length,
  };
  const dataSource = formatTableData(data);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Placement Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Air Date",
      dataIndex: "airDate",
      key: "airDate",
    },
  ];

  return (
    <div>
      <h1 id="license_history" className="landing-anchor">
        License History
      </h1>
      <Card css={styles.panel}>
        <div css={styles.licenseSummary}>
          <span className="licenseType">
            <span className="count">{licenseCount.episodes || 0}</span> TV
            Episodes
          </span>
          <span className="licenseType">
            <span className="count">{licenseCount.movies || 0}</span> Movies
          </span>
          <span className="licenseType">
            <span className="count">{licenseCount.games || 0}</span> Games
          </span>
        </div>
        <Table
          css={styles.licenseTable}
          columns={columns}
          dataSource={dataSource}
          locale={{ emptyText: "No license history found for this song." }}
          pagination={false}
        />
      </Card>
    </div>
  );
}
