import React, { ReactElement } from "react";
import { MapContainer, CircleMarker, TileLayer, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import LoadingSpinner from "../loading-spinner";
import styles from "./styles";

export interface IMapCity {
  name?: string;
  code?: string;
  listeners?: number;
  coordinates?: [number, number];
}

interface IProps {
  data?: IMapCity[];
  minLat: number;
  maxLat: number;
  minLong: number;
  maxLong: number;
}

function BubbleMap({
  data,
  minLat,
  maxLat,
  minLong,
  maxLong,
}: IProps): ReactElement {
  const centerLat = (minLat + maxLat) / 2;
  const distanceLat = maxLat - minLat;
  const bufferLat = distanceLat * 0.05;
  const centerLong = (minLong + maxLong) / 2;
  const distanceLong = maxLong - minLong;
  const bufferLong = distanceLong * 0.15;

  if (!data) {
    return <LoadingSpinner />;
  }
  return (
    <div css={styles.mapWrapper}>
      <MapContainer
        style={{ height: "480px", width: "100%" }}
        zoom={1}
        center={[centerLat, centerLong]}
        bounds={[
          [minLat - bufferLat, minLong - bufferLong],
          [maxLat + bufferLat, maxLong + bufferLong],
        ]}
      >
        <TileLayer url="https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=m9qKhm2qfDE17hAwgpIH" />
        {data &&
          data
            .filter((city) => {
              return city.coordinates && city.listeners;
            })
            .map((city) => {
              return (
                <CircleMarker
                  key={`${city.name}-${city.listeners}`}
                  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                  center={[city.coordinates![0], city.coordinates![1]]}
                  radius={
                    city.listeners && 15 * Math.log(city.listeners / 100000)
                  }
                  fillOpacity={0.5}
                >
                  <Tooltip direction="right" offset={[-8, -2]} opacity={1}>
                    <span>{`${
                      city.name
                    }: Listeners  ${city.listeners?.toLocaleString()}`}</span>
                  </Tooltip>
                </CircleMarker>
              );
            })}
      </MapContainer>
    </div>
  );
}

export default BubbleMap;
