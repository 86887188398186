import {
  artistPopularityShare,
  artistRankRanges,
  CompanySizes,
  defaultArtistRankMultiple,
  defaultTrackPopularityMultiple,
  EstimateBoundPercent,
  LicenseEstimateFactors,
  PriceEstimate,
  PriceEstimateFactors,
  trackPopularityRanges,
  trackPopularityShare,
} from "./types";

type factorKey = keyof PriceEstimateFactors;
const requiredFields: factorKey[] = [
  "trackPopularity",
  "companySize",
  "media",
  "territory",
  "term",
  "exclusivity",
  "scriptLength",
  "numberOfScripts",
];

export function isValidFactors(
  factors: Partial<PriceEstimateFactors>
): factors is PriceEstimateFactors {
  for (let i = 0; i < requiredFields.length; i += 1) {
    if (typeof factors[requiredFields[i]] === "undefined") {
      return false;
    }
  }
  return true;
}

function artistRankMultiple(rank: number): number {
  for (let i = 0; i < artistRankRanges.length; i++) {
    const range = artistRankRanges[i];
    if (rank <= range.minRank) {
      return range.multiple;
    }
  }
  return defaultArtistRankMultiple;
}

function trackPopularityMultiple(popularity: number): number {
  for (let i = 0; i < trackPopularityRanges.length; i++) {
    const range = trackPopularityRanges[i];
    if (popularity <= range.minPopularity) {
      return range.multiple;
    }
  }
  return defaultTrackPopularityMultiple;
}

function estimateRounder(estimate: number): number {
  if (estimate > 1000) {
    const scaledDown = estimate / 1000;
    const rounded = Math.round(scaledDown);
    return rounded * 1000;
  } else {
    return Math.round(estimate);
  }
}

function calculateLicenseFactor(
  factors: PriceEstimateFactors,
  exclusivityAlreadyIncluded: boolean
): number {
  return Object.keys(LicenseEstimateFactors).reduce(
    (prev: number, current: string) => {
      const currentKey = current as keyof typeof LicenseEstimateFactors;
      if (exclusivityAlreadyIncluded && currentKey === "exclusivity") {
        return prev;
      }
      const factorDetails = LicenseEstimateFactors[currentKey];
      const factorSelection = factors[currentKey];
      const details = factorDetails.options[factorSelection];
      if (!details) {
        // eslint-disable-next-line no-console
        console.error(
          `Invalid selection (${factorSelection}) for factor ${currentKey}`
        );
        return prev;
      }
      const factorContribution = factorDetails.weight * details.multiple;
      return prev + factorContribution;
    },
    0
  );
}

const baseCost = 1000;
const defaultArtistRank = 5_000_000;
const exclusivityIncludedPrice = 365_500;
export function priceEstimate(factors: PriceEstimateFactors): PriceEstimate {
  const artistPopularityCost =
    baseCost *
    artistRankMultiple(factors.artistRank || defaultArtistRank) *
    artistPopularityShare;
  const trackPopularityCost =
    baseCost *
    trackPopularityMultiple(factors.trackPopularity) *
    trackPopularityShare;

  const popularityBaseCost = artistPopularityCost + trackPopularityCost;

  const exclusivityIncluded = popularityBaseCost >= exclusivityIncludedPrice;
  const licenseFactor = calculateLicenseFactor(factors, exclusivityIncluded);
  const licenseCost = popularityBaseCost * licenseFactor;

  const companyFactor = CompanySizes[factors.companySize].multiple;
  const companyCost = licenseCost * companyFactor;

  const estimate = companyCost;
  const range = estimate * EstimateBoundPercent;

  const low = estimateRounder(estimate - range);
  const high = estimateRounder(estimate + range);

  return {
    low,
    estimate,
    high,
  };
}
