import { css } from "@emotion/react";

export default {
  mapWrapper: css`
    flex: 1;
    margin-top: 3.9375rem;
    .leaflet-control-attribution {
      display: none;
    }
  `,
};
