export interface Factor {
  display: string;
  multiple: number;
}

export interface FactorMap {
  [key: string]: Factor;
}

export const EstimateBoundPercent = 0.3;

export const CompanySizes = {
  small: {
    display: "Small",
    multiple: 0.8,
  },
  medium: {
    display: "Medium",
    multiple: 0.9,
  },
  large: {
    display: "Large",
    multiple: 1.0,
  },
  global: {
    display: "Global",
    multiple: 1.1,
  },
};
export type CompanySize = keyof typeof CompanySizes;

export const LicensableMedias = {
  all: {
    display: "All Media",
    multiple: 2.5,
  },
  tvOnline: {
    display: "TV & Online",
    multiple: 1.6,
  },
  tv: {
    display: "TV",
    multiple: 1.0,
  },
  online: {
    display: "Online",
    multiple: 0.5,
  },
  radio: {
    display: "Radio",
    multiple: 0.4,
  },
  cinema: {
    display: "Cinema",
    multiple: 0.3,
  },
};
export type LicenseMedia = keyof typeof LicensableMedias;

export const LicensableTerritories = {
  worldwide: {
    display: "Worldwide",
    multiple: 3.0,
  },
  europe: {
    display: "Europe",
    multiple: 1.1,
  },
  westEurope: {
    display: "West Europe",
    multiple: 0.7,
  },
  usaCanada: {
    display: "USA & Canada",
    multiple: 1.2,
  },
  asia: {
    display: "Asia",
    multiple: 1.0,
  },
  twoCountry: {
    display: "2 Countries",
    multiple: 0.4,
  },
  oneCountry: {
    display: "1 Country",
    multiple: 0.2,
  },
};
export type LicenseTerritory = keyof typeof LicensableTerritories;

export const LicensableTerms = {
  twoYears: {
    display: "2 Years",
    multiple: 1.8,
  },
  oneYear: {
    display: "1 Year",
    multiple: 1.0,
  },
  sixMonths: {
    display: "6 Months",
    multiple: 0.75,
  },
  threeMonths: {
    display: "3 Months",
    multiple: 0.45,
  },
};
export type LicenseTerm = keyof typeof LicensableTerms;

export const LicensableExclusivities = {
  none: {
    display: "None",
    multiple: 1.0,
  },
  productCategory: {
    display: "Product Category",
    multiple: 6,
  },
  full: {
    display: "Full",
    multiple: 12,
  },
};
export type LicenseExclusivity = keyof typeof LicensableExclusivities;

export const LicensableScriptLengths = {
  ninetySecondsPlus: {
    display: "Full Track Length",
    multiple: 1.5,
  },
  upToSixtySeconds: {
    display: "60",
    multiple: 1.2,
  },
  upToThirtySeconds: {
    display: "30",
    multiple: 1.0,
  },
  upToFifteenSeconds: {
    display: "15",
    multiple: 0.8,
  },
};
export type LicenseScriptLength = keyof typeof LicensableScriptLengths;

export const LicensableNumberOfScripts = {
  1: {
    display: "1",
    multiple: 1.0,
  },
  2: {
    display: "2",
    multiple: 1.05,
  },
  3: {
    display: "3",
    multiple: 1.1,
  },
  4: {
    display: "4",
    multiple: 1.15,
  },
  unlimited: {
    display: "Unlimited",
    multiple: 1.5,
  },
};
export type LicenseNumberOfScripts = keyof typeof LicensableNumberOfScripts;

export const LicenseEstimateFactors = {
  media: {
    weight: 0.3,
    options: LicensableMedias as FactorMap,
  },
  territory: {
    weight: 0.3,
    options: LicensableTerritories as FactorMap,
  },
  term: {
    weight: 0.3,
    options: LicensableTerms as FactorMap,
  },
  exclusivity: {
    weight: 0.05,
    options: LicensableExclusivities as FactorMap,
  },
  numberOfScripts: {
    weight: 0.025,
    options: LicensableNumberOfScripts as FactorMap,
  },
  scriptLength: {
    weight: 0.025,
    options: LicensableScriptLengths as FactorMap,
  },
};

export const artistPopularityShare = 0.8;
export const defaultArtistRankMultiple = 7.5;
export const artistRankRanges = [
  {
    multiple: 500,
    minRank: 500,
  },
  {
    minRank: 1_000,
    multiple: 400,
  },
  {
    minRank: 2_000,
    multiple: 150,
  },
  {
    minRank: 5_000,
    multiple: 125,
  },
  {
    minRank: 10_000,
    multiple: 100,
  },
  {
    minRank: 20_000,
    multiple: 75,
  },
  {
    minRank: 50_000,
    multiple: 50,
  },
  {
    minRank: 100_000,
    multiple: 35,
  },
  {
    minRank: 500_000,
    multiple: 17,
  },
  {
    minRank: 1_000_000,
    multiple: 10,
  },
];

export const trackPopularityShare = 0.2;
export const defaultTrackPopularityMultiple = 30;
export const trackPopularityRanges = [
  {
    minPopularity: 0.91,
    multiple: 150,
  },
  {
    minPopularity: 0.81,
    multiple: 130,
  },
  {
    minPopularity: 0.71,
    multiple: 110,
  },
  {
    minPopularity: 0.61,
    multiple: 90,
  },
  {
    minPopularity: 0.51,
    multiple: 80,
  },
  {
    minPopularity: 0.41,
    multiple: 70,
  },
  {
    minPopularity: 0.31,
    multiple: 60,
  },
  {
    minPopularity: 0.21,
    multiple: 50,
  },
  {
    minPopularity: 0.11,
    multiple: 40,
  },
];

export interface PriceEstimateFactors {
  artistRank?: number;
  trackPopularity: number;
  companySize: CompanySize;
  media: LicenseMedia;
  territory: LicenseTerritory;
  term: LicenseTerm;
  exclusivity: LicenseExclusivity;
  scriptLength: LicenseScriptLength;
  numberOfScripts: LicenseNumberOfScripts;
}

export interface PriceEstimate {
  estimate: number;
  low: number;
  high: number;
}
