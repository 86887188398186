import { css } from "@emotion/react";

const styles = {
  spotifyEmbed: css`
    width: 420px;
    height: 500px;
    border: none;
  `,
  inlinePlayer: css`
    width: 100%;
    height: 80px;
    border: none;
    position: fixed;
    bottom: 0;
    z-index: 3;
  `,
};
export default styles;
