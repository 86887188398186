import React, { ReactElement, useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import PageLoading from "src/components/page-loading";
import { getArtistBase } from "src/api";
import { ArtistBaseMetadata } from "src/api/types/artist-base-metadata";
import Navbar from "src/components/nav-bar";
import ArtistLandingPage from "..";

interface RouteParams {
  artistId: string;
}

export default function ArtistLandingContainer(): ReactElement {
  const { artistId } = useParams<RouteParams>();

  const [isBaseLoading, setIsBaseLoading] = useState(true);
  const [artistBase, setArtistBase] = useState<ArtistBaseMetadata | null>(null);

  useEffect(() => {
    getArtistBase(artistId)
      .then((results) => {
        setArtistBase(results);
        setIsBaseLoading(false);
      })
      .catch((err) => {
        throw err;
      });
  }, [artistId]);

  if (isBaseLoading || artistBase === null) {
    return <PageLoading />;
  }

  return (
    <Fragment>
      <Navbar title={artistBase.spotify.name} />
      <ArtistLandingPage artistBase={artistBase} artistId={artistId} />
    </Fragment>
  );
}
