import { css } from "@emotion/react";

export default {
  notFound: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1.875rem;
    h4 {
      font-size: 1.1875rem;
    }
    p {
      font-size: 1.1875rem;
      color: #515151;
    }
  `,
  results: css`
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3.5rem;
    left: 0;
    right: 0;
    z-index: 2;
    font-family: proxima-nova, sans-serif;
    max-width: 45rem;
    display: flex;
    gap: 8.8125rem;
    ul {
      list-style-type: none;
      padding-left: 0;
    }
    .song-list,
    .artist-list {
      flex: 1 1 0;
      min-width: 19.375rem;
      h2 {
        font-size: 1.25rem;
        padding-left: 0.625rem;
        font-weight: 600;
      }
    }
    li {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      margin-bottom: 0.875rem;
      &:hover {
        background: rgba(84, 84, 84, 0.05);
        border-radius: 0.25rem;
        cursor: pointer;
      }
    }

    button {
      text-align: left;
      background: transparent;
      box-shadow: 0 0 0 transparent;
      border: 0 solid transparent;
      text-shadow: 0 0 0 transparent;
      padding: 0;
      &:hover {
        cursor: pointer;
        background: transparent;
        box-shadow: 0 0 0 transparent;
        border: 0 solid transparent;
        text-shadow: 0 0 0 transparent;
      }
    }

    button:active {
      outline: none;
      border: none;
    }

    button:focus {
      outline: 0;
    }
  `,
};
