import React, { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./styles";

interface IProps {
  title: string;
}
export default function Navbar({ title }: IProps): ReactElement {
  const isSticky = () => {
    const nav = document.querySelector(".nav");
    const scrollTop = window.scrollY;
    if (nav) {
      return scrollTop >= 250
        ? nav.classList.add("is-sticky")
        : nav.classList.remove("is-sticky");
    }
    return false;
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  return (
    <div>
      <div className="nav" css={styles.nav}>
        <Link to="/"> Commercial Search | </Link>
        {title}
      </div>
    </div>
  );
}
