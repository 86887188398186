import { css, SerializedStyles } from "@emotion/react";
import colors from "src/colors";

export default {
  headerContainer: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 17rem;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    padding-left: 1.5rem;
    margin-top: 2rem;
    .sticky-tabs {
      &.is-sticky {
        position: fixed;
        z-index: 1051;
        top: 65px;
      }
    }
  `,
  infoContainer: css`
    display: flex;
  `,
  textContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
  `,
  name: css`
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.375rem;
  `,
  genres: css`
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: ${colors.gray.dark};
    text-transform: capitalize;
  `,
  image: css`
    width: 8.75rem;
    border-radius: 6.25rem;
    margin: 1.875rem;
    margin-top: 3.75rem;
  `,
  tabs: css`
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${colors.gray.dark};
    margin: 2rem;

    &:focus {
      font-weight: 600;
      color: ${colors.black};
      border-bottom: 0.188rem solid ${colors.black};
    }
  `,
  focus: css`
    font-weight: 600;
    color: ${colors.black};
    border-bottom: 0.188rem solid #0552b5;
  `,
  zIndex: css`
    z-index: 1052;
  `,
};
