import { css, SerializedStyles } from "@emotion/react";
import colors from "src/colors";

export default {
  menuWrapper: css`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5px;
  `,
  inputRow: css`
    display: flex;
    margin-top: 20px;
  `,
  searchInput: css`
    width: 405px;
    height: 50px;
    margin-left: auto;
    border-radius: 4px;
  `,
  advancedButtonGroup: css`
    float: right;
    margin-top: 40px;
  `,
  advancedButton(isSearch: boolean): SerializedStyles {
    return css`
      width: 109px;
      height: 50px;
      margin-left: ${isSearch ? "12px" : "0"};
      background: ${isSearch ? "#2186EB" : "rgba(223, 230, 239, 0.5)"};
      border-radius: 4px;
      font-size: 16px;
      line-height: 20px;

      span {
        color: ${isSearch ? "#fff" : "#000"};
      }
    `;
  },
  advancedText: css`
    text-align: left !important;
    color: #334e68;
    font-weight: 600;
  `,
  menu: css`
    width: 650px;
    z-index: 1;
    background: #fff;
    border-radius: 4px;
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 20px;
    box-shadow: unset 2px 4px rgba(0, 0, 0, 0.15);

    @media (min-width: 1024px) {
      width: 1140px !important;
    }

    @media (min-width: 768px) {
      width: 668px !important;
    }
  `,
  popupText: css`
    text-align: left !important;
    color: #334e68;
  `,
  advancedSubText: css`
    text-align: left !important;
    color: #334e68;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 30px;
  `,
  searchBox: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: rgba(5, 82, 181, 0.05);
    border-radius: 4px;
    margin-bottom: 50px;
  `,
  advanceBtn: css`
    text-align: left !important;
    background: #627d980d !important;
    border: unset;
    span {
      color: #000;
    }
  `,
  wrapperFixed: css`
    position: absolute;
    top: 70vh;
    width: 100%;
    .___sb {
      display: none;
      transition: all ease 0.3s;
    }
    .ant-affix {
      z-index: 110;
      .___sb {
        display: flex;
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  `,
  wrapper: css`
    display: flex;
    justify-content: center;
    pointer-events: none;
    > * {
      pointer-events: all;
    }
  `,
  search: {
    select: css`
      width: 170px;
      color: #000;
      .ant-select-selection-item {
        font-size: 16px !important;
      }

      :not(.ant-select-customize-input) .ant-select-selector {
        border: unset;
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #fff;
      }

      span {
        color: #000;
      }
    `,
    dropdown: css`
      border-radius: 4px;
      background: #fff;
      padding: 10px;
      width: 180px;
      z-index: 3;
    `,
    dropdownItem(isSelected: boolean): SerializedStyles {
      return css`
        color: #000;
        background: ${isSelected ? "#E6EEF8" : "#fff"};
        padding-top: 5px;
        padding-left: 5px;
        padding-bottom: 5px;
        margin-right: 10px;
      `;
    },
    divider: css`
      width: 0.75px;
      height: 75%;
      background: #bcccdc;
    `,
    section: css`
      margin-top: 50px;
      @media (max-width: 768px) {
        flex-direction: column;
        .ant-space-item {
          width: 80vw;
          margin-right: 0 !important;
          margin-bottom: 15px;
        }
      }
    `,
    border: css`
      width: 650px;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0%;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 4px;
      background: ${colors.white};

      &:hover {
        border: 1px solid ${colors.blue.f80};
      }

      input {
        border: none;
        &:focus {
          outline: 0;
          border: none;
          box-shadow: none;
        }
      }
      .ant-input-affix-wrapper {
        border: none;
        border-right: 1px solid #c4c4c4;
        border-radius: 4px;
        padding: 0 1rem 0 0;
        outline: 0;
      }
      .ant-input-affix-wrapper-focused {
        box-shadow: none;
      }

      @media (min-width: 1024px) {
        width: 1140px !important;
      }

      @media (min-width: 768px) {
        width: 660px !important;
      }

      @media (max-width: 600px) {
        font-size: 16px !important;
        line-height: 42px !important;
      }
    `,
    searchIcon: css`
      width: 38px;
      height: 38px;
      background: #2186eb;
      border-radius: 38px;
      float: right;
      color: #fff;
      cursor: pointer;
    `,
    input(size: "small" | "regular"): SerializedStyles {
      return css`
        width: 650px !important;
        .ant-select-selector,
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          height: ${size === "small" ? "40px" : "50px"} !important;
          font-size: 18px !important;
          padding-left: 10.5px !important;
          text-align: left;
          line-height: ${size === "small" ? "38px" : "48px"} !important;
        }
        height: ${size === "small" ? "40px" : "50px"} !important;
        max-width: 41.25rem;
        border-radius: 1px !important;
        font-size: 18px !important;
        text-align: left;
        line-height: ${size === "small" ? "38px" : "48px"} !important;

        @media (min-width: 1024px) {
          width: 1140px !important;
        }

        @media (min-width: 768px) {
          width: 670px !important;
        }

        @media (max-width: 600px) {
          font-size: 16px !important;
          line-height: 42px !important;
        }

        input {
          padding-left: 10.5px !important;
          height: ${size === "small" ? "38px" : "48px"} !important;
          line-height: normal !important;
          @media (max-width: 600px) {
            height: 42px !important;
          }
        }

        .ant-select-selection-placeholder {
          color: #bfbfbf;
          opacity: 1;
          font-family: Proxima Nova, sans-serif;
        }
      `;
    },
    result: {
      track: css`
        margin: 6px 6px 6px 0;
      `,

      titleAndArtist: css`
        display: flex;
        flex-direction: column;
        span:nth-of-type(1) {
          color: ${colors.black} !important;
        }
        .title {
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.5rem;
        }
        .artist {
          font-size: 0.875rem;
          color: #545454;
          line-height: 1.125rem;
        }
      `,
    },
  },
};
