import React, { ReactElement, useEffect, useState } from "react";
import { Card } from "antd";
import { Line } from "@ant-design/charts";
import { getArtistStats } from "src/api";
import { ArtistStats } from "src/api/types/artist-stats";
import { Stat } from "src/api/types/stats-result";
import moment from "moment";
import colors from "src/colors";
import abbreviateNumber from "src/utils/helpers";
import styles from "./styles";
import LoadingSpinner from "../loading-spinner";

interface Props {
  title: string;
  stat: ArtistStats;
  chartmetricId?: number;
  displayStat: number | string | undefined;
  dateFormat: string;
  selectedOption: string;
  since?: moment.Moment;
  until?: moment.Moment;
  tooltip?: ReactElement;
}

export default function ArtistStatGraph({
  title,
  chartmetricId,
  stat,
  displayStat,
  dateFormat,
  selectedOption,
  since,
  until,
  tooltip,
}: Props): ReactElement {
  const [stats, setStats] = useState<Stat[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    if (!chartmetricId) {
      return;
    }

    getArtistStats(chartmetricId, stat, since, until)
      .then((results) => {
        setStats(results.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  }, [chartmetricId, stat, since, until]);

  if (!stats || stats.length === 0) {
    return <></>;
  }

  let min = Number.MAX_SAFE_INTEGER;
  let max = 0;
  stats.forEach((m) => {
    if (m.value < min) {
      min = m.value;
    }
    if (m.value > max) {
      max = m.value;
    }
  });

  // wrangle time stat into display friendly format for graph
  const graphStats = stats.map((s) => {
    return {
      value: s.value,
      time: moment(s.time).format(dateFormat),
    };
  });

  const graphConfig = {
    data: graphStats,
    xField: "time",
    yField: "value",
    color: colors.blue.light,
    meta: {
      value: {
        min,
        max,
      },
    },
    yAxis: {
      label: {
        formatter: (v: string) => {
          return abbreviateNumber(parseFloat(v), 2);
        },
      },
    },
    lineStyle: {
      lineWidth: 3,
    },
    smooth: true,
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Card>
      <div css={styles.card}>
        <div css={styles.cardInfo}>
          <p>{title}</p>
          <div css={styles.toolTip}>{tooltip}</div>
          <h2>{displayStat}</h2>

          {selectedOption === "All Time" ? (
            <span>All Time</span>
          ) : (
            <span css={styles.dateRange}>
              {since?.format(dateFormat)} - {until?.format(dateFormat)}
            </span>
          )}
        </div>
        <Line {...graphConfig} />
      </div>
    </Card>
  );
}
