import React, { ReactElement, Fragment } from "react";
import { TrackBaseMetadata } from "src/api/types/track-base-metadata";

import TrackHeader from "src/components/track-header";
import TrackSpotifyPanel from "src/components/track-spotify-panel";
import TrackSocialPanel from "src/components/track-social-panel";
import TrackPlaylists from "src/components/track-playlists";
import TrackPriceEstimator from "src/components/track-price-estimator";
import SongDetailsContainer from "src/components/song-details/container";
import TrackLicensingLoader from "src/components/track-licensing-display/loader";

import styles from "./styles";

interface IProps {
  trackBase: TrackBaseMetadata;
  trackId: string;
  artistRank?: number;
  isrc?: string;
}

export default function TrackLandingPage({
  trackBase,
  trackId,
  artistRank,
  isrc,
}: IProps): ReactElement {
  const chartmetricId = trackBase?.analysis?.id;

  return (
    <Fragment>
      <TrackHeader
        image={trackBase.spotify.imageFull}
        name={trackBase.spotify.title}
        artists={trackBase.spotify.artists}
      />
      <div css={styles.wrapper}>
        <SongDetailsContainer trackBase={trackBase} isrc={isrc} />
        <h1 id="stats" className="landing-anchor">
          Stats
        </h1>
        <TrackSpotifyPanel
          trackBase={trackBase}
          chartmetricId={chartmetricId}
        />
        <TrackSocialPanel trackBase={trackBase} chartmetricId={chartmetricId} />
        <TrackPlaylists trackBase={trackBase} />
        <TrackLicensingLoader spotifyId={trackId} />
        <TrackPriceEstimator
          artistRank={artistRank}
          trackPopularity={trackBase.spotify.popularity}
          trackName={trackBase.spotify.title}
          artists={trackBase.spotify.artists}
        />
      </div>
    </Fragment>
  );
}
