import { css } from "@emotion/react";
import colors from "src/colors";

export default {
  card: css`
    padding-bottom: 1rem;
  `,
  cardInfo: css`
    font-family: proxima-nova, sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.625rem;
    margin-left: 1rem;
    margin-bottom: 3rem;

    p {
      margin-bottom: 8px;
      display: inline-block;
    }

    h2 {
      margin-top: 4px;
      margin-bottom: 2px;
      font-size: 1.5rem;
      font-weight: 600;
      color: ${colors.blue.dark};
    }

    span {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  `,
  dateRange: css`
    font-family: proxima-nova, sans-serif;
    font-size: 0.875rem;
    color: ${colors.blue.gray};
    margin-bottom: 3.5625rem;
  `,
  toolTip: css`
    display: inline-block;
    margin-left: 1rem;
  `,
};
