import React, { ReactElement } from "react";
import { ArtistAudienceBreakdown } from "src/api/types/artist-audience-breakdown";
import LoadingSpinner from "../loading-spinner";
import BasicDemographics from "./basic-demographics";

interface IProps {
  audienceDetails: ArtistAudienceBreakdown | null;
}

const ArtistAudiencePanel = ({ audienceDetails }: IProps): ReactElement => {
  if (audienceDetails === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <h2 id="audience" className="landing-anchor">
        Audience
      </h2>
      <BasicDemographics audienceDetails={audienceDetails} />
    </>
  );
};

export default ArtistAudiencePanel;
