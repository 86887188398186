import React, { ReactElement, useEffect, useState } from "react";
import { SimpleArtist } from "src/api/types/track-base-metadata";
import { artistLink } from "src/utils/links";
import { Link } from "react-router-dom";
import styles from "./styles";

interface TrackProps {
  image: string | undefined;
  name: string;
  artists: SimpleArtist[];
}
const TrackHeader = ({ image, name, artists }: TrackProps): ReactElement => {
  const [selectedTab, setSelectedTab] = useState<string | undefined>();
  const artistArray = artists.map((artist) => {
    return artist;
  });

  const isSticky = () => {
    const header = document.querySelector(".sticky-tabs");
    const scrollTop = window.scrollY;
    if (header) {
      return scrollTop >= 250
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    }
    return false;
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  return (
    <div css={styles.headerContainer}>
      <div css={styles.infoContainer}>
        <img src={image} alt="artist" css={styles.image} />
        <div css={styles.textContainer}>
          <h1 css={styles.name}>{name}</h1>
          <div>
            {artistArray.map((artist, index) => {
              return (
                <Link css={styles.artists} to={artistLink(artist)}>
                  {artist.name}
                  {index !== artistArray.length - 1 && ", "}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="sticky-tabs" css={styles.tabContainer}>
        <a
          onClick={() => setSelectedTab("details")}
          href="#details"
          css={[styles.tabs, selectedTab === "details" && styles.focus]}
        >
          Song Details
        </a>
        <a
          onClick={() => setSelectedTab("stats")}
          href="#stats"
          css={[styles.tabs, selectedTab === "stats" && styles.focus]}
        >
          Stats
        </a>
        <a
          onClick={() => setSelectedTab("license_history")}
          href="#license_history"
          css={[styles.tabs, selectedTab === "license_history" && styles.focus]}
        >
          License History
        </a>
        <a href="#pricing" css={styles.tabs}>
          Pricing
        </a>
      </div>
    </div>
  );
};

export default TrackHeader;
