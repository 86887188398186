import { css } from "@emotion/react";

export default {
  licenseSummary: css`
    font-family: "proxima-nova", sans-serif;
    .count {
      font-weight: 600;
      padding-right: 0.3125rem;
    }
    .licenseType {
      display: inline-block;
      padding-right: 1.875rem;
      margin-bottom: 2.5rem;
    }
  `,
  licenseTable: css`
    thead {
      th {
        background: #c4c4c4;
        font-weight: 600;
        font-size: 0.875rem;
      }
    }
    tbody {
      tr td {
        padding: 0.75rem;
      }

      tr:nth-child(2n) td {
        background-color: #fafafa;
      }
    }
  `,
  panel: css`
    flex: 1;
    margin-bottom: 1.875rem;
    box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
  `,
  tableWrapper: css`
    margin-bottom: 7.8125rem;
  `,
  artistThumbnail: css`
    height: 3rem;
    width: 3rem;
  `,
  trackTitle: css`
    padding-left: 1rem;
  `,
};
