export default function abbreviateNumber(
  number: number,
  places: number
): string | undefined {
  let returnValue;
  const decimalPlaces = 10 ** places;
  const abbrev = ["K", "M", "B", "T"];

  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    const size = 10 ** ((i + 1) * 3);

    if (size <= number) {
      let rounded = Math.round((number * decimalPlaces) / size) / decimalPlaces;

      if (rounded === 1000 && i < abbrev.length - 1) {
        rounded = 1;
        i += 1;
      }

      returnValue = `${rounded}${abbrev[i]}`;
      break;
    }
  }
  return returnValue || number.toString();
}
