import React, { ReactElement } from "react";
import { ArtistBaseMetadata } from "src/api/types/artist-base-metadata";
import moment from "moment";
import { ArtistAudienceBreakdown } from "src/api/types/artist-audience-breakdown";
import FanbaseGrowthCard from "./fanbase-growth-card";
import EngagementCard from "./engagement-card";
import LoadingSpinner from "../loading-spinner";

interface IProps {
  artistBase: ArtistBaseMetadata;
  until: moment.Moment;
  dropdownOptions: string[];
  audienceDetails: ArtistAudienceBreakdown | null;
}

const ArtistSocialMediaPanel = ({
  artistBase,
  until,
  dropdownOptions,
  audienceDetails,
}: IProps): ReactElement => {
  const chartmetricId = artistBase?.analysis?.ids.chartmetricIds[0];

  if (audienceDetails === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <h2 className="landing-anchor" id="social-media">
        Social Media
      </h2>
      <FanbaseGrowthCard
        artistBase={artistBase}
        until={until}
        dropdownOptions={dropdownOptions}
        chartmetricId={chartmetricId}
      />
      <EngagementCard audienceDetails={audienceDetails} />
    </>
  );
};

export default ArtistSocialMediaPanel;
