import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { SimpleArtist } from "src/api/types/track-base-metadata";
import { Alert, Button, Card, Form, Input, Select } from "antd";
import {
  LicensableMedias,
  LicenseMedia,
  LicensableTerritories,
  LicenseTerritory,
  LicensableTerms,
  LicenseTerm,
  LicensableExclusivities,
  LicenseExclusivity,
  LicensableNumberOfScripts,
  LicenseNumberOfScripts,
  LicenseScriptLength,
  LicensableScriptLengths,
  PriceEstimateFactors,
  isValidFactors,
  PriceEstimate,
  priceEstimate,
} from "src/lib/price-estimation";
import { useLocalStorage } from "src/utils/hooks/use-local-storage";
import { startCase } from "lodash";
import styles from "./styles";
import LoadingSpinner from "../loading-spinner";

const { TextArea } = Input;

const googleSheetsScriptURL =
  "https://script.google.com/macros/s/AKfycbx0wMI09g6tIzNhAKux2JwAMKhkM2Wa-aroIRdJxtMDAbGhgvULbsZ8_sJAxF5ZdAKz/exec";

interface IProps {
  artistRank?: number;
  trackPopularity: number;
  trackName: string;
  artists: SimpleArtist[];
}

function currencyFormat(num: number): string {
  const formattedValue = num
    .toString(10)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return `$${formattedValue}`;
}

const TrackPriceEstimator = ({
  trackName,
  trackPopularity,
  artistRank,
  artists,
}: IProps): ReactElement => {
  const [media, setMedia] = useLocalStorage<LicenseMedia | undefined>(
    "licenseMedia",
    undefined
  );
  const [territory, setTerritory] = useLocalStorage<
    LicenseTerritory | undefined
  >("licenseTerritory", undefined);
  const [term, setTerm] = useLocalStorage<LicenseTerm | undefined>(
    "licenseTerm",
    undefined
  );
  const [exclusivity, setExclusivity] = useLocalStorage<
    LicenseExclusivity | undefined
  >("licenseExclusivity", undefined);
  const [numberOfScripts, setNumberOfScripts] = useLocalStorage<
    LicenseNumberOfScripts | undefined
  >("licenseScriptsCount", undefined);
  const [scriptLength, setScriptLength] = useLocalStorage<
    LicenseScriptLength | undefined
  >("licenseScriptsLength", undefined);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [estimateRequested, setEstimateRequested] = useState<boolean>(false);
  const [isAccurateEstimate, setIsAccurateEstimate] = useState<boolean>(true);
  const [musicExpertEstimateLower, setMusicExpertEstimateLower] = useState<
    string | undefined
  >();
  const [musicExpertEstimateUpper, setMusicExpertEstimateUpper] = useState<
    string | undefined
  >();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userName, setUserName] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");

  const artistNames = artists.map((artist) => {
    return artist.name;
  });
  const { Option } = Select;

  const factors = useMemo((): Partial<PriceEstimateFactors> => {
    return {
      artistRank,
      trackPopularity,
      companySize: "global", // TODO Get a valid value pulled in
      media,
      territory,
      term,
      exclusivity,
      scriptLength,
      numberOfScripts,
    };
  }, [
    artistRank,
    trackPopularity,
    media,
    territory,
    term,
    exclusivity,
    scriptLength,
    numberOfScripts,
  ]);

  const handleSubmit = () => {
    setEstimateRequested(true);
  };

  useEffect(() => {
    if (isValidFactors(factors)) {
      setDisabled(false);
    }
  }, [factors]);

  const estimateLoading = factors.artistRank === -1;
  let estimate: PriceEstimate | undefined;
  if (!estimateLoading && estimateRequested && isValidFactors(factors)) {
    estimate = priceEstimate(factors);
  }

  const submitFeedback = () => {
    if (
      !isAccurateEstimate &&
      (!musicExpertEstimateUpper || !musicExpertEstimateLower)
    ) {
      setIsError(
        "Please enter a value for lower and upper estimate before submitting."
      );
      return;
    }
    const form = new FormData();
    if (artistRank) {
      form.append("CPP Rank", artistRank.toString());
    }
    form.append("Artist", artistNames.join(","));
    form.append("Song", trackName);
    if (trackPopularity) {
      form.append("Spotify Popularity", trackPopularity.toString());
    }
    if (media) {
      form.append("Media Type", startCase(media));
    }
    if (territory) {
      form.append("Territory", startCase(territory));
    }
    if (term) {
      form.append("Term Duration", startCase(term));
    }
    if (exclusivity) {
      form.append("Exclusivity", startCase(exclusivity));
    }
    if (numberOfScripts) {
      form.append("No. of scripts", numberOfScripts.toString());
    }
    if (scriptLength) {
      form.append("Length of scripts", startCase(scriptLength));
    }
    if (estimate) {
      form.append("Price Tool lower", currencyFormat(estimate.low));
      form.append("Price Tool upper", currencyFormat(estimate.high));
    }
    if (musicExpertEstimateLower) {
      form.append(
        "Music Expert Lower",
        currencyFormat(Number(musicExpertEstimateLower))
      );
    }
    if (musicExpertEstimateUpper) {
      form.append(
        "Music Expert Upper",
        currencyFormat(Number(musicExpertEstimateUpper))
      );
    }
    if (userName) {
      form.append("User", userName);
    }
    if (additionalNotes) {
      form.append("Additional notes", additionalNotes);
    }
    setIsSubmitting(true);
    fetch(googleSheetsScriptURL, { method: "POST", body: form })
      .then(() => {
        setIsSuccess(true);
        setIsError("");
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsError("Sorry, there was an error submitting this form.");
        setIsSubmitting(false);
      });
  };

  return (
    <div css={styles.component}>
      <h1 id="pricing" className="landing-anchor">
        Price Estimate for <span>{trackName}</span> by{" "}
        <span>{artistNames.join(",")}</span>
      </h1>
      <p>
        <i>This is an alpha feature.</i>
      </p>
      <Form css={styles.dropdownSelectors} layout="vertical">
        <Form.Item label="Media Type">
          <Select
            className="select"
            size="large"
            placeholder="Choose"
            style={{ width: 140 }}
            value={media}
            onSelect={setMedia}
          >
            {Object.keys(LicensableMedias).map((option) => {
              return (
                <Option key={option} value={option}>
                  {LicensableMedias[option as LicenseMedia].display}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Territory">
          <Select
            className="select"
            size="large"
            placeholder="Choose"
            style={{ width: 160 }}
            value={territory}
            onSelect={setTerritory}
          >
            {Object.keys(LicensableTerritories).map((option) => {
              return (
                <Option key={option} value={option}>
                  {LicensableTerritories[option as LicenseTerritory].display}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Term Duration">
          <Select
            className="select"
            style={{ width: 125 }}
            size="large"
            placeholder="Choose"
            value={term}
            onSelect={setTerm}
          >
            {Object.keys(LicensableTerms).map((option) => {
              return (
                <Option key={option} value={option}>
                  {LicensableTerms[option as LicenseTerm].display}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Exclusivity">
          <Select
            className="select"
            style={{ width: 175 }}
            size="large"
            placeholder="Choose"
            value={exclusivity}
            onSelect={setExclusivity}
          >
            {Object.keys(LicensableExclusivities).map((option) => {
              return (
                <Option key={option} value={option}>
                  {
                    LicensableExclusivities[option as LicenseExclusivity]
                      .display
                  }
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Number of Scripts">
          <Select
            className="select"
            style={{ width: 125 }}
            size="large"
            placeholder="Choose"
            value={numberOfScripts}
            onSelect={setNumberOfScripts}
          >
            {Object.keys(LicensableNumberOfScripts).map((option) => {
              return (
                <Option key={option} value={option}>
                  {
                    LicensableNumberOfScripts[option as LicenseNumberOfScripts]
                      .display
                  }
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Length of Scripts">
          <Select
            className="select"
            style={{ width: 175 }}
            size="large"
            placeholder="Choose"
            value={scriptLength}
            onSelect={setScriptLength}
          >
            {Object.keys(LicensableScriptLengths).map((option) => {
              return (
                <Option key={option} value={option}>
                  {
                    LicensableScriptLengths[option as LicenseScriptLength]
                      .display
                  }
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
      {estimateRequested && estimateLoading && <LoadingSpinner />}
      {estimate && (
        <div css={styles.paddingBottom}>
          <p>
            Based on the selected licensing terms, the cost of licensing this
            song may be in this range.
          </p>
          <span id="footnote">
            *This is just an illustrative estimate and needs to go through our
            formal clearance process
          </span>
          <Card css={styles.estimate}>
            {currencyFormat(estimate.low)} <span>to</span>{" "}
            {currencyFormat(estimate.high)}
            <div css={styles.feedback}>
              <p>Is this estimate accurate? (Please submit to confirm.)</p>
              <label htmlFor="yesAnswer">
                <input
                  id="yesAnswer"
                  type="radio"
                  name="choice-radio"
                  onClick={() => setIsAccurateEstimate(true)}
                  checked={isAccurateEstimate}
                />
                Yes
              </label>
              <label htmlFor="noAnswer">
                <input
                  id="noAnswer"
                  type="radio"
                  name="choice-radio"
                  onClick={() => setIsAccurateEstimate(false)}
                  checked={!isAccurateEstimate}
                />
                No
              </label>
              <div css={styles.feedbackForm}>
                <Input
                  placeholder="Lower limit"
                  addonBefore="$"
                  name="min"
                  disabled={isAccurateEstimate}
                  type="number"
                  onChange={(event) =>
                    setMusicExpertEstimateLower(event.target.value)
                  }
                />
                <p>to</p>
                <Input
                  placeholder="Upper limit"
                  addonBefore="$"
                  name="max"
                  disabled={isAccurateEstimate}
                  type="number"
                  onChange={(event) =>
                    setMusicExpertEstimateUpper(event.target.value)
                  }
                />
              </div>
              <Input
                css={styles.nameInput}
                placeholder="Your email address (optional)"
                id="nameInput"
                name="name"
                type="email"
                onChange={(event) => setUserName(event.target.value)}
              />
              <TextArea
                rows={2}
                placeholder="Additional notes"
                onChange={(event) => setAdditionalNotes(event.target.value)}
              />
              <Button
                data-heap-id="song-submit-estimate"
                css={styles.button}
                type="primary"
                size="small"
                onClick={submitFeedback}
                disabled={isSuccess}
                loading={isSubmitting}
              >
                <span css={styles.white}>Submit</span>
              </Button>
              {isSuccess && (
                <Alert type="success" message="Thank you for the feedback!" />
              )}
              {isError && (
                <Alert
                  type="error"
                  message="Please enter both lower and upper values before submitting."
                />
              )}
            </div>
          </Card>
        </div>
      )}
      {!estimateRequested && (
        <Button
          data-heap-id="song-get-estimate"
          css={styles.button}
          type="primary"
          size="large"
          onClick={handleSubmit}
          disabled={disabled}
        >
          Get Estimate
        </Button>
      )}
    </div>
  );
};

export default TrackPriceEstimator;
