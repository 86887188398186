export enum TrackStats {
  shazamCounts = "shazamCounts",
  soundcloudPlays = "soundcloudPlays",
  spotifyStreams = "spotifyStreams",
  spotifyPopularity = "spotifyPopularity",
  tiktokVideos = "tiktokVideos",
  youtubeViews = "youtubeViews",
}

export type TrackStatTypes = keyof typeof TrackStats;
