import React, { ReactElement, useEffect, useState, Fragment } from "react";
import { Typography } from "antd";
import { getTrackLyrics } from "src/api";
import { TrackLyrics } from "src/api/types/track-lyrics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles";
import LyricsModal from "../lyrics-modal";

const { Paragraph } = Typography;

interface Props {
  isrc?: string;
  setPublisher?: (value: string) => void;
  artists?: string[];
  songTitle: string;
}

export default function LyricsDisplay({
  isrc,
  setPublisher,
  artists,
  songTitle,
}: Props): ReactElement {
  const [lyrics, setLyrics] = useState<TrackLyrics | null>(null);
  const [isLyricModalOpen, setIsLyricModalOpen] = useState(false);
  const title = `${songTitle} by ${artists && artists.join(", ")}`;

  useEffect(() => {
    if (!isrc) {
      return;
    }

    getTrackLyrics(isrc)
      .then((results) => {
        setLyrics(results);
        if (setPublisher) {
          setPublisher(results.copyright.replace("Lyrics © ", ""));
        }
      })
      .catch((err) => {
        throw err;
      });
  }, [isrc, setPublisher]);

  if (!lyrics) {
    return <></>;
  }

  return (
    <Fragment>
      <FontAwesomeIcon
        css={styles.expandIcon}
        onClick={() => setIsLyricModalOpen(true)}
        icon={faExpandAlt}
      />
      <Paragraph css={styles.lyrics}>{lyrics.lyrics}</Paragraph>
      <Paragraph>{lyrics.copyright}</Paragraph>
      <Paragraph>Writer: {lyrics.writer}</Paragraph>
      <LyricsModal
        isOpen={isLyricModalOpen}
        setIsOpen={setIsLyricModalOpen}
        lyrics={lyrics}
        title={title}
      />
    </Fragment>
  );
}
