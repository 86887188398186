const nonUrlCharacters = /([^a-zA-ZÀ-ſƀ-ɏḂ-ỳ]+)/g;
export function urlize(input: string): string {
  return input.replace(nonUrlCharacters, "-").substr(0, 40);
}

export function trackLink(track: { title: string; trackId: string }): string {
  return `/${urlize(track.title)}/st/${track.trackId}`;
}

export function artistLink(a: { id: string; name: string }): string {
  return `/${urlize(a.name)}/sa/${a.id}`;
}
